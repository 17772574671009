import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { FIELD } from '../../constants/app-constants'
import CustomSelect from './CustomSelect'

const CustomShiftSelect = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
    }, [props.state.site])

    useEffect(() => {
        if (!props.state.site) {
            // If no site is selected than clear shift select 
            props.setValue(`${FIELD.team_a}`, null)
            props.setValue(`${FIELD.team_b}`, null)
            props.setValue(`${FIELD.team_c}`, null)
            props.setValue(`${FIELD.team_d}`, null)
        }
        if (!props.getValues(`${FIELD.site}`)) {
            // Disabled the shift if there is no site
            setIsDisabled(true)
        }
        else {
            setIsDisabled(false)
        }
    }, [props.state])

    useEffect(() => {
        if (props.getValues(`${FIELD.date_a}`)) {
            let date_b = moment(props.getValues(`${FIELD.date_a}`), "DD-MM-YYYY").add(7, 'days')._d;
            let date_c = moment(date_b, "DD-MM-YYYY").add(7, 'days')._d;
            let date_d = moment(date_c, "DD-MM-YYYY").add(7, 'days')._d;

            props.setValue(`${FIELD.date_b}`, date_b)
            props.setValue(`${FIELD.date_c}`, date_c)
            props.setValue(`${FIELD.date_d}`, date_d)
        }
        else{
            props.setValue(`${FIELD.date_b}`, null)
            props.setValue(`${FIELD.date_c}`, null)
            props.setValue(`${FIELD.date_d}`, null)
        }
    }, [props.getValues(`${FIELD.date_a}`)])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={props.options}
        />
    )
}

export default CustomShiftSelect