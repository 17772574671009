import { constant, errorMessages, s3Credential } from "./constants";
import S3 from "react-aws-s3";
import { FIELD, KEYS } from "../constants/app-constants";
import { GetTokenLocalStorage } from "../services/localStorage/localStorage";
import moment from "moment";

window.Buffer = window.Buffer || require("buffer").Buffer;

export const UploadFileToS3 = async (file, dirName) => {
    let newFileName = file.name + Math.floor(Date.now() / 1000);
    if (file.name.split("")[0] == ".") {
        newFileName = newFileName.slice(1, newFileName.length)
    }
    newFileName = newFileName.split(" ").join("-")

    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(s3Credential.fileSize, 10)) {
        return {
            success: false,
            message: errorMessages.fileSize
        }
    }

    const config = {
        bucketName: s3Credential.bucketName,
        dirName: dirName,
        region: s3Credential.region,
        accessKeyId: s3Credential.accessKeyId,
        secretAccessKey: s3Credential.secretAccessKey,
        s3Url: s3Credential.s3EndPoint,
    };

    const ReactS3Client = new S3(config);
    let responseData = {};
    await ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data && data.status === 204) {
            responseData = {
                success: true,
                message: errorMessages.fileSuccess,
                url: dirName + "/" + newFileName,
                mime_type: file.type
            }
        } else {
            responseData = {
                success: false,
                message: errorMessages.fileError
            }
        }
    })
    return responseData;
}

export const HandleShow = (allowedRoles, selectedRole) => {
    const isAllowed = allowedRoles.includes(selectedRole)
    if (isAllowed) {
        return "d-block"
    }
    else {
        return "d-none"
    }
}

export const HandleRegister = (allowedRoles, selectedRole) => {
    return allowedRoles.includes(selectedRole)
}

export const CleanAndFormatData = (data) => {
    let user_profiles = []
    for (let key in data) {
        if (data[key]) {
            if (KEYS.includes(key)) {
                data[key] = data[key].value.toString()
            }
            if (key === FIELD.date || key === FIELD.start_date || key === FIELD.end_date || key === FIELD.date_a || key === FIELD.date_b || key === FIELD.date_c || key === FIELD.date_d) {
                data[key] = ConvertToYearMonthDate(data[key])
            }
            if (key === FIELD.user_profiles) {
                data[key].forEach((item) => {
                    user_profiles.push(item?.value)
                })

                data[key] = user_profiles
            }
        }
        else {
            delete data[key]
        }
    }

    return data
}

export const ConvertToYearMonthDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
}

export const GetOnlyMondays = (providedDate) => {
    // Get date on onChange and convert it to 'YYYY-MM-DD' and disable all mondays
    let array = []
    const date = ConvertToYearMonthDate(providedDate)
    const year = date.split("-")[0]
    const month = date.split("-")[1]

    if (providedDate) {
        for (var i = 1; i < 32; i++) {
            if (i < 10) {
                let date = new Date(`${year}-${month}-0${i}`)
                if (date.getDay() !== 1) {
                    array.push(date)
                }
            }
            else {
                let date = new Date(`${year}-${month}-${i}`)
                if (date.getDay() !== 1) {
                    array.push(date)
                }
            }
        }
    }
    return array
}

export const GetOnlyThursdaysAndFridays = (providedDate) => {
    // Get date on onChange and convert it to 'YYYY-MM-DD' and disable all days except thursdays and fridays
    let array = []
    const date = ConvertToYearMonthDate(providedDate)
    const year = date.split("-")[0]
    const month = date.split("-")[1]

    if (providedDate) {
        for (var i = 1; i < 32; i++) {
            if (i < 10) {
                let date = new Date(`${year}-${month}-0${i}`)
                if (date.getDay() !== 4 && date.getDay() !== 5) {
                    array.push(date)
                }
            }
            else {
                let date = new Date(`${year}-${month}-${i}`)
                if (date.getDay() !== 4 && date.getDay() !== 5) {
                    array.push(date)
                }
            }
        }
    }
    return array
}

export const GetFormattedDate = (created_at) => {
    const date = moment.utc(created_at).fromNow()
    return date
}

export const GetBackgroundColor = (index) => {
    if (index == 0) {   // Team A Bg
        return "#0070C0"
    }
    else if (index == 1) {  // Team B Bg
        return "#5DB5F4"
    }
    else if (index == 2) {  // Team C Bg
        return "#24D474"
    }
    else if (index == 3) {  // Team D Bg
        return "#D4D4D4"
    }
}

export const GenerateDeviceToken = () => {
    return Math.random(0).toString(36).substr(2)
}
