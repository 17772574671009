import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import { config } from "../../utils/helper"

export const AddPost = (data) => api.post(`${constant.V1}${apiUrl.posts}/`, data)
export const GetPosts = (params) => api.get(`${constant.V1}${apiUrl.posts}/`, { params })
export const GetSinglePost = (id) => api.get(`${constant.V1}${apiUrl.posts}/${id}/`)
export const EditPost = (id, data) => api.put(`${constant.V1}${apiUrl.posts}/${id}/`, data)
export const DeletePost = (id) => api.delete(`${constant.V1}${apiUrl.posts}/${id}/`)
export const BulkDeletePost = (ids) => api.delete(`${constant.V1}${apiUrl.posts}${apiUrl.bulk_delete}/`, { data: { ids } })