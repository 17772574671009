import React, { useContext, useState } from 'react'
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap'
import { tabs_array } from '../utils/constants'
import SetWorkSchedule from "../components/dashboard/SetWorkSchedule"
import { AddDupontSchedule, AddEoweoSchedule, AddFourFiveSchedule, AddFourOnFourOffSchedule, AddSevenOnSevenOffSchedule } from '../services/work-schedules/work-schedules'
import Assets from '../constants/images'
import { TriggerAllSites } from '../App'

const SetWorkSchedulePage = () => {
    const [key, setKey] = useState('tabs-0');
    const showStartDateIndex = [1, 2, 3, 4]

    const handleShowStartDate = (index) => {
        return showStartDateIndex.includes(index)
    }

    const handleApiMethod = (index) => {
        if (index == 0) {
            return AddDupontSchedule
        }
        else if (index == 1) {
            return AddFourOnFourOffSchedule
        }
        else if (index == 2) {
            return AddEoweoSchedule
        }
        else if (index == 3) {
            return AddFourFiveSchedule
        }
        else if (index == 4) {
            return AddSevenOnSevenOffSchedule
        }
    }

    const handlePlaceholder = (index) => {
        const mondayDaysIndex = [0, 1, 2, 3]
        if (mondayDaysIndex.includes(index)) {
            return "Select Monday Days *"
        }
        else {
            return "Select Thursday And Friday Days *"
        }
    }

    const handleScheduleImage = (index) => {
        if (index == 0) {
            return Assets.DupontImage
        }
        else if (index == 1) {
            return Assets.FourOnFourImage
        }
        else if (index == 2) {
            return Assets.EoweoImage
        }
        else if (index == 3) {
            return Assets.FourFiveImage
        }
        else if (index == 4) {
            return Assets.SevenOnSevenImage
        }
    }

    return (
        <div className='pages set-work-schedule-page'>
            <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => setKey(k)}>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={3}>
                        <Nav variant="pills" className="flex-column">
                            {
                                tabs_array.map((name, index) => (
                                    <Nav.Item key={index} className="mb-4">
                                        <Nav.Link eventKey={`tabs-${index}`}>{name}</Nav.Link>
                                    </Nav.Item>
                                ))
                            }
                        </Nav>
                    </Col>

                    <Col xs={12} md={12} lg={12} xl={9}>
                        <Tab.Content>
                            {
                                tabs_array.map((name, index) => (
                                    <Tab.Pane eventKey={`tabs-${index}`} className="">
                                        {
                                            key === `tabs-${index}` &&
                                            <SetWorkSchedule
                                                eventKey={key}
                                                index={index}
                                                placeholder={handlePlaceholder(index)}
                                                method={handleApiMethod(index)}
                                                showStartDate={handleShowStartDate(index)}
                                                scheduleImage={handleScheduleImage(index)}
                                            />
                                        }
                                    </Tab.Pane>
                                ))
                            }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div >
    )
}

export default SetWorkSchedulePage