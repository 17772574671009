import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import DashboardLayout from '../layouts/dashboard/DashboardLayout'
import HomePage from '../pages/HomePage'
import CalendarEventsPage from '../pages/CalendarEventsPage'
import ShiftSchedulePage from '../pages/ShiftSchedulePage'
import SetupPageLayout from '../layouts/page/SetupPageLayout'
import CompetencyPage from '../pages/CompetencyPage'
import ManageUsersPage from '../pages/ManageUsersPage'
import ManageTeamsPage from '../pages/ManageTeamsPage'
import ManageSitesPage from '../pages/ManageSitesPage'
import ScheduleReportPage from '../pages/ScheduleReportPage'
import SetupPostInformationPage from '../pages/SetupPostInformationPage'
import VacationAllotmentPage from '../pages/VacationAllotmentPage'
import SiteDepartmentsDetailPage from '../pages/SiteDepartmentsDetailPage'
import TeamUsersDetailPage from '../pages/TeamUsersDetailPage'
import SetOffItemInformationPage from '../pages/SetOffItemInformationPage'
import MyProfilePage from '../pages/MyProfilePage'
import SettingsSidebar from '../components/dashboard/SettingsSidebar'
import TermsAndContitionsPage from '../pages/TermsAndContitionsPage'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import FaqsPage from '../pages/FaqsPage'
import ChangePasswordPage from '../pages/ChangePasswordPage'
import AuthLayout from '../layouts/authentication/AuthLayout'
import SigninForm from '../components/authentication/SigninForm'
import SignupForm from '../components/authentication/SignupForm'
import ForgotPasswordForm from '../components/authentication/ForgotPasswordForm'
import VerificationCodeForm from '../components/authentication/VerificationCodeForm'
import ResetPasswordForm from '../components/authentication/ResetPasswordForm'
import SplashScreenPage from '../pages/SplashScreenPage'
import AuthPrivacyPolicyPage from "../components/authentication/AuthPrivacyPolicyPage"
import AuthTermsAndConditionsPage from "../components/authentication/AuthTermsAndConditionsPage"
import ErrorPage from '../pages/ErrorPage'
import SetWorkSchedulePage from '../pages/SetWorkSchedulePage'
import PublicRoute from './PublicRoute'
import ProtectedRoute from './ProtectedRoute'
import { roles } from '../utils/constants'

const Navigation = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        // move to top on page change
        window.scrollTo(0, 0);
    }, [navigate, location])

    return (
        <Routes>
            {/* ************* Authentication Routes ************* */}
            <Route element={<PublicRoute />}>
                <Route path='/' element={<SplashScreenPage />} ></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/signin' element={<AuthLayout children={<SigninForm />} />}></Route>
            </Route>

            {/* <Route element={<PublicRoute />}>
                <Route path='/signup' element={<AuthLayout children={<SignupForm />} />}></Route>
            </Route> */}

            <Route element={<PublicRoute />}>
                <Route path='/forgot-password' element={<AuthLayout children={<ForgotPasswordForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/verify-otp' element={<AuthLayout children={<VerificationCodeForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/reset-password' element={<AuthLayout children={<ResetPasswordForm />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/terms-and-conditions' element={<AuthLayout children={<AuthTermsAndConditionsPage />} />}></Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path='/privacy-policy' element={<AuthLayout children={<AuthPrivacyPolicyPage />} />}></Route>
            </Route>


            {/* ************* Dashboard Routes ************* */}

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.supervisor]} />}>
                <Route path="/dashboard" element={<DashboardLayout children={<HomePage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.supervisor, roles.employee]} />}>
                <Route path="/calender-events" element={<DashboardLayout children={<CalendarEventsPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.supervisor, roles.employee]} />}>
                <Route path="/shift-schedule" element={<DashboardLayout children={<ShiftSchedulePage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor]} />}>
                <Route path="/setup/set-work-schedule" element={<DashboardLayout children={<SetupPageLayout children={<SetWorkSchedulePage />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/setup/post-information" element={<DashboardLayout children={<SetupPageLayout children={<SetupPostInformationPage />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/setup/item-information" element={<DashboardLayout children={<SetupPageLayout children={<SetOffItemInformationPage />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/setup/vacation-allotment" element={<DashboardLayout children={<SetupPageLayout children={<VacationAllotmentPage />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.supervisor]} />}>
                <Route path="/competency" element={<DashboardLayout children={<CompetencyPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor]} />}>
                <Route path="/manage-users" element={<DashboardLayout children={<ManageUsersPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/manage-teams" element={<DashboardLayout children={<ManageTeamsPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/manage-teams/team/:id" element={<DashboardLayout children={<TeamUsersDetailPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/manage-sites" element={<DashboardLayout children={<ManageSitesPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/manage-sites/site/:id" element={<DashboardLayout children={<SiteDepartmentsDetailPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user]} />}>
                <Route path="/schedule-report" element={<DashboardLayout children={<ScheduleReportPage />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor, roles.employee]} />}>
                <Route path="/settings/my-profile" element={<DashboardLayout children={<MyProfilePage children={<SettingsSidebar />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor, roles.employee]} />}>
                <Route path="/settings/change-password" element={<DashboardLayout children={<ChangePasswordPage children={<SettingsSidebar />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor, roles.employee]} />}>
                <Route path="/settings/terms-and-conditions" element={<DashboardLayout children={<TermsAndContitionsPage children={<SettingsSidebar />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor, roles.employee]} />}>
                <Route path="/settings/privacy-policy" element={<DashboardLayout children={<PrivacyPolicyPage children={<SettingsSidebar />} />} />}></Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[roles.super_user, roles.site_admin, roles.supervisor, roles.employee]} />}>
                <Route path="/settings/faqs" element={<DashboardLayout children={<FaqsPage children={<SettingsSidebar />} />} />}></Route>
            </Route>

            {/* ******* NOTE: PLACE ALL ROUTES BEFORE ERROR ROUTE ******* */}
            <Route path="*" element={<ErrorPage />} />
        </Routes >
    )
}

export default Navigation