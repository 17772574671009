import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import Loader from '../loader/Loader'

const InformationBox = (props) => {
    const siteData = props?.siteData
    const departmentData = props?.departmentData

    const returnData = (id) => {
        if (id === "company") {
            return (
                <>
                    <h1>{props?.name}</h1>
                    <div className="info-wrapper mt-3">
                        <span className='mt-3'>{siteData?.location} Administrator: <b>{siteData?.site_admin?.full_name || 'N/A'}</b></span>
                    </div>
                </>
            )
        }
        else if (id === "site") {
            return (
                <>
                    <h1>{props?.name}: {siteData?.location || "N/A"} </h1>
                    <div className="info-wrapper mt-3">
                        <span className='mt-3'>{siteData?.location} Administrator: <b>{siteData?.site_admin?.full_name || 'N/A'}</b></span>
                        <span className='mt-3'>Site Schedule: <b>{siteData?.details?.schedule_type_display || 'N/A'}</b></span>
                        <span className='mt-3'>OT Criteria: <b>{siteData?.details?.ot_criteria_display || 'N/A'}</b></span>
                        <span className='mt-3'>Slider Tolerance: <b>{siteData?.details?.slider_tolerance || 'N/A'}</b></span>
                        <span className='mt-3'>Double Time Day: <b>{siteData?.details?.double_time_day || 'N/A'}</b></span>
                    </div>
                </>
            )
        }
        else if (id === "department") {
            return (
                <>
                    <h1>{props?.name}: {departmentData?.name || "N/A"} </h1>
                    <div className="info-wrapper mt-3">
                        <span className='mt-3'>A Shift Supervisor: <b>{departmentData?.team_a_supervisor || 'N/A'}</b></span>
                        <span className='mt-3'>B Shift Supervisor: <b>{departmentData?.team_b_supervisor || 'N/A'}</b></span>
                        <span className='mt-3'>C Shift Supervisor: <b>{departmentData?.team_c_supervisor || 'N/A'}</b></span>
                        <span className='mt-3'>D Shift Supervisor: <b>{departmentData?.team_d_supervisor || 'N/A'}</b></span>
                    </div>
                </>
            )
        }
    }

    return (
        <div className='information-box'>
            <Row>
                {
                    props?.isLoading ?
                        <Col xs={12}>
                            <Skeleton height={15} width={150} count={2.5} />
                        </Col>
                        :
                        <Col xs={12}>
                            {returnData(props?.id)}
                        </Col>
                }
            </Row>
        </div>
    )
}

export default InformationBox