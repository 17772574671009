import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import { ToastContainer } from 'react-toastify'
import CreateShiftSchedulePopup from '../components/popup/CreateShiftSchedulePopup'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import {
    BulkDeleteSchedule,
    DeleteSchedule,
    GetSchedules,
    RerunSchedule,
    UploadSchedule
} from '../services/schedules/schedules'
import { error, success } from '../constants/msg'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import { BsDownload } from 'react-icons/bs'
import { saveAs } from 'file-saver';
import { MdLoop } from "react-icons/md"
import Loader from '../components/loader/Loader'
import { EditProfile } from "../services/me/me";
import { SetAuthUserLocalStorage } from "../services/localStorage/localStorage";
import { BulkCreateUser } from "../services/users/users";
import UploadFilePopup from "../components/popup/UploadFilePopup";
import { UserRole } from '../App'
import { roles } from '../utils/constants'

const ShiftSchedulePage = () => {
    const [createShiftSchedulePopup, setCreateShiftSchedulePopup] = useState(false)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [rows, setRows] = useState([])
    const [file, setFile] = useState()
    const [uploadCsvPopup, setUploadCsvPopup] = useState(false)
    const [userRole, setUserRole] = useContext(UserRole)
    const [isUploadDisabled, setIsUploadDisabled] = useState(false)
    const [customError, setCustomError] = useState("")

    const [columns, setColumns] = useState([
        {
            name: 'Duration',
            selector: rowData => rowData.duration,
            sortable: true,
        },
        {
            name: 'Schedule Period ',
            selector: rowData => rowData.schedule_period,
            sortable: true,
        },
        {
            name: 'Site Name',
            selector: rowData => rowData.site_name,
            sortable: true,
        },
        {
            name: 'Department',
            selector: rowData => rowData.department,
            sortable: true,
        },
        {
            name: 'Action',
            selector: rowData => rowData.schedule_files,
            cell: (rowData, row) => <RefreshAndDownloadButton rowData={rowData} btnText="Download" name="download" icon={<BsDownload className='me-2' />} />
        },
    ])


    // ==== Below comment show the refresh schedule column on the basis of role =========== 

    if (userRole !== roles.employee) {
        columns.splice(3, 0, {
            name: 'Refresh Schedule',
            selector: rowData => rowData.id,
            cell: (rowData, row) => <RefreshAndDownloadButton rowData={rowData} btnText="Refresh Schedule" name="refresh" icon={<MdLoop className='me-2' />} />
        });
    }

    function LoadingDots() {
        const [dots, setDots] = useState('');

        useEffect(() => {
            const intervalId = setInterval(() => {
                setDots(prevDots => prevDots.length >= 3 ? '' : prevDots + '.');
            }, 500);

            return () => clearInterval(intervalId);
        }, []);

        return <span>Loading{dots}</span>;
    }

    const RefreshAndDownloadButton = ({ rowData, btnText, name, icon }) => {
        const [isLoading, setIsLoading] = useState(false)

        const refreshSchedule = async (data) => {
            setIsLoading(true)
            try {
                const res = await RerunSchedule(data?.id).then(() => {
                    setTimeout(async () => {
                        await getSchedules()
                        setIsLoading(false)
                    }, 6000)
                })
            }
            catch (e) {
                setIsLoading(false)
                error(e?.response?.data?.message)
            }
        }

        const openPdfInNewTab = (pdfUrl) => {
            const newWindow = window.open(pdfUrl, '_blank');
        };


        const downloadPdf = (data) => {
            setIsLoading(true);
            for (let i = 0; i < data.schedule_files.length; i++) {
                const pdfUrl = data.schedule_files[i];
                openPdfInNewTab(pdfUrl);
            }
            setIsLoading(false);
        };

        return (
            <>
                {isLoading ? <span className='btn-download'><LoadingDots /></span> : <span className='btn-download' onClick={() => name === "refresh" ? refreshSchedule(rowData) : downloadPdf(rowData)}><span>{icon}{btnText}</span></span>}
            </>
        )
    }

    const getSchedules = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
            }
            const res = await GetSchedules(params)
            const data = res.data
            console.log("Create Shift Schedule data", data)
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    duration: item?.durations_display || "-",
                    schedule_period: item?.start_date + " - " + item?.end_date || "-",
                    site_name: item.site_name || "-",
                    department: item.department_name || "-",
                    schedule_files: item?.schedule_files?.length <= 0 ? "Please wait..." : item?.schedule_files?.map((item) => item?.file)
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e?.response?.data?.message)
        }
    }

    const deleteSchedule = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteSchedule(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteSchedule(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getSchedules()

        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        getSchedules()
    }, [page.currentPage])


    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };




    const uploadSchedule = async (formData) => {
        setIsUploadDisabled(true)
        setCustomError("")
        try {
            const res = await UploadSchedule(formData)
            setUploadCsvPopup(false)
            setIsUploadDisabled(false)
            setCustomError("")
            success(res.data.message)
            getSchedules()
        }
        catch (e) {
            setIsUploadDisabled(false)
            setCustomError(e?.response?.data?.message)
        }
    }


    return (
        <>
            <UploadFilePopup uploadCsvPopup={uploadCsvPopup} setUploadCsvPopup={setUploadCsvPopup} uploadSchedule={uploadSchedule} isUploadDisabled={isUploadDisabled} customError={customError} setCustomError={setCustomError} />
            <CreateShiftSchedulePopup createShiftSchedulePopup={createShiftSchedulePopup} setCreateShiftSchedulePopup={setCreateShiftSchedulePopup} rows={rows} setRows={setRows} getSchedules={getSchedules} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteSchedule} />


            <div className='pages shift-schedule-page'>
                <Container>
                    <Row>
                        <Col xs={12} sm={4} lg={4} xl={4} className="pt-2">
                            <h1>Shift Schedule</h1>
                        </Col>
                        {userRole === roles.employee ? null : <><Col xs={12} sm={6} md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }}>

                            {/*<Button className='mt-4 mt-sm-0 w-100 btn-solid' onClick={handleClick}>+ Uplaod schedule</Button>*/}
                            {/*<input*/}
                            {/*    type="file"*/}
                            {/*    accept="application/pdf,application/vnd.ms-excel"*/}
                            {/*    ref={hiddenFileInput}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    style={{display: 'none'}} />*/}
                            <Button className='mt-4 mt-sm-0 w-100 btn-solid' onClick={() => setUploadCsvPopup(true)}>+ Upload Shift Schedule</Button>
                        </Col>
                            <Col xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Button className='mt-4 mt-sm-0 w-100 btn-solid' onClick={() => setCreateShiftSchedulePopup(true)}>+ Create Shift Schedule</Button>
                            </Col></>}
                    </Row>
                    <Row className="justify-content-between">
                        <Col xs={12} className="mt-4">
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={userRole === roles?.employee ? false : true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ShiftSchedulePage