import React from 'react'
import DashboardNavbar from './DashboardNavbar'

const DashboardLayout = ({ children }) => {
    return (
        <div className='dashboard-layout'>
            <DashboardNavbar />
            {children}
        </div>
    )
}

export default DashboardLayout