import React, { useContext, useState, useEffect } from 'react'
import { GetDepartmentsBySite } from '../../services/departments/departments'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { AllSites } from '../../App'

const CustomDepartmentSelect = (props) => {
    const [options, setOptions] = useState()
    const [departmentData, setDepartmentData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [allSites, setAllSites] = useContext(AllSites)

    const getDepartmentsBySite = async () => {
        setIsLoading(true)

        try {
            const params = {}
            const res = await GetDepartmentsBySite(props?.state?.site?.value, params)
            const data = res.data.data
            setDepartmentData(data)
            setIsLoading(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsLoading(false)
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        const temp_options = []
        for (let i = 0; i < allSites.length; i++) {
            if (allSites[i].id == props?.state?.site?.value) {
                allSites[i].departments?.map((data, index) => {
                    temp_options.push({
                        label: data?.name,
                        value: data?.id
                    })
                })

                setOptions(temp_options)
                break;
            }
        }

        if (props.callApi && props.state.site) {
            getDepartmentsBySite()
        }

        setIsLoading(false)
        setIsDisabled(false)

        setTimeout(() => {
            if (props?.isPreFilled) {
                props.setValue(`${FIELD.department}`, temp_options[0])
            }
        }, 200)
    }, [props.state.site, allSites])

    useEffect(() => {
        if (!props.state.department) {
            // If no department is selected than clear team,user select 
            props.setValue(`${FIELD.team}`, null)
            props.setValue(`${FIELD.user}`, null)

            props.setValue(`${FIELD.team_a}`, null)
            props.setValue(`${FIELD.team_b}`, null)
            props.setValue(`${FIELD.team_c}`, null)
            props.setValue(`${FIELD.team_d}`, null)
        }

        if (!props.getValues(`${FIELD.site}`)) {
            // Disabled the department if there is no site
            setIsDisabled(true)
        }
    }, [props.state])

    useEffect(() => {
        if (props?.state?.departmentName?.length > 0) {
            setIsDisabled(true)
            props.setValue(`${FIELD.department}`, null)
        }
        else {
            setIsDisabled(false)
        }
    }, [props.state.departmentName])

    useEffect(() => {
        if (props.state.department && departmentData) {
            for (let i = 0; i < departmentData.length; i++) {
                if (departmentData[i].id == props.state.department.value) {
                    props.setValue(`${FIELD.team_a}`, { label: departmentData[i].team_a_display, value: departmentData[i].team_a })
                    props.setValue(`${FIELD.team_b}`, { label: departmentData[i].team_b_display, value: departmentData[i].team_b })
                    props.setValue(`${FIELD.team_c}`, { label: departmentData[i].team_c_display, value: departmentData[i].team_c })
                    props.setValue(`${FIELD.team_d}`, { label: departmentData[i].team_d_display, value: departmentData[i].team_d })

                    if (props.selectedOptions) {
                        props.selectedOptions.push(
                            {
                                label: "shift_a",
                                value: departmentData[i].team_a,
                            },
                            {
                                label: "shift_b",
                                value: departmentData[i].team_b,
                            },
                            {
                                label: "shift_c",
                                value: departmentData[i].team_c,
                            },
                            {
                                label: "shift_d",
                                value: departmentData[i].team_d,
                            }
                        )
                    }
                    break;
                }
            }
        }
    }, [props.state.department, props.getValues(`${FIELD.department}`)])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled ? true : isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomDepartmentSelect