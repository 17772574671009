import React from 'react'
import { GetTokenLocalStorage, GetUserRoleLocalStorage } from "../services/localStorage/localStorage"
import { Route, Navigate, Outlet, useNavigate } from "react-router";
import { roles } from '../utils/constants';

const PublicRoute = () => {
    const token = GetTokenLocalStorage()
    const role = GetUserRoleLocalStorage()

    const handleNavigation = () => {
        if (role === roles.super_user) {
            return "/dashboard"
        }
        else if (role === roles.site_admin) {
            return "/setup/set-work-schedule"
        }
        else if (role === roles.supervisor || role === roles.employee) {
            return "/calender-events"
        }
    }

    return (
        !token ? <Outlet /> : <Navigate to={handleNavigation()} />
    )
}

export default PublicRoute