import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomPostTypeSelect from '../forms/CustomPostTypeSelect'
import { ToastContainer } from 'react-toastify'
import ClearFormPopup from './ClearFormPopup'
import CustomInput from '../forms/CustomInput'
import Loader from '../loader/Loader'
import { AddPost } from '../../services/posts/posts'
import { error, success } from '../../constants/msg'
import { CleanAndFormatData } from '../../utils/helper'

const AddSetupPostInformationPopup = ({ addSetupPostInformationPopup, setAddSetupPostInformationPopup, rows, setRows }) => {
    const { register, handleSubmit, watch, reset, getValues, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.type]: null,
            [FIELD.title]: "",
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null })
        setClearFormPopup(false)
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        try {
            const res = await AddPost(formData)
            const obj = {
                id: res?.data?.data?.id || "-",
                post_name: res?.data?.data?.title || "-",
                post_type: res?.data?.data?.type_display || "-",
                site_name: res?.data?.data?.site_name || "-",
                department_name: res?.data?.data?.department_name || "-",
            }
            setRows([obj, ...rows])
            success(res.data.message)
            setAddSetupPostInformationPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [addSetupPostInformationPopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />


            <Modal
                className='theme-popup add-setup-post-information-popup'
                show={addSetupPostInformationPopup}
                onHide={() => setAddSetupPostInformationPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add New Post</h1>
                                        <span className='times-icon' onClick={() => setAddSetupPostInformationPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>

                                    {/* POST NAME INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.title}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.POST_NAME,
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.POST_MIN,
                                                    message: VALIDATIONS_TEXT.POST_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.POST_MAX,
                                                    message: VALIDATIONS_TEXT.POST_MAX
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.title}
                                                    name={FIELD.title}
                                                    placeholder="Post Name *"
                                                    type="text"
                                                    label="Post Name *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.title] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.title].message}</small>}
                                    </Col>

                                    {/* POST TYPE SELECT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.type}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.POST_TYPE,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomPostTypeSelect
                                                    {...field}
                                                    name={FIELD.type}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    placeholder="Post Type *"
                                                />
                                            )}
                                        />
                                        {errors[FIELD.type] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.type].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button type="submit" disabled={isDisabled} className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddSetupPostInformationPopup