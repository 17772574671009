import React, { useContext, useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomInput from '../forms/CustomInput'
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { Logout } from '../../services/auth/auth'
import Loader from '../loader/Loader'
import { EmptyLocalStorage } from '../../services/localStorage/localStorage'
import { error, success } from '../../constants/msg'
import { UserData, UserRole } from '../../App'
import { useNavigate } from 'react-router-dom'

const LogoutPopup = ({ logoutPopup, setLogoutPopup }) => {
    const navigate = useNavigate()
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [isDisabled, setIsDisabled] = useState(false)
    const { register, handleSubmit, watch, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const logout = async () => {
        // Logout user and after logout clear localStorage and Contexts
        setIsDisabled(true)

        try {
            const res = await Logout()
            setUserData(null)
            setUserRole(null)
            EmptyLocalStorage()
            setLogoutPopup(false)
            setIsDisabled(false)
            success(res.data.message)

            setTimeout(() => {
                navigate("/signin")
            }, 1200);
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    return (
        <>
            <Modal
                className='theme-popup logout-confirm-popup'
                show={logoutPopup}
                onHide={() => setLogoutPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Logout</h1>
                                        <span className='times-icon' onClick={() => setLogoutPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='mt-4'>
                            <Row>
                                <Col xs={12}>
                                    <p>Are you sure you want to logout?</p>
                                </Col>
                            </Row>
                        </div>

                        <Row className="justify-content-end mt-4">
                            <Col xs={6} sm={4}>
                                <Button className='btn-outline w-100' onClick={() => setLogoutPopup(false)}>Cancel</Button>
                            </Col>

                            <Col xs={6} sm={4}>
                                <Button onClick={() => logout()} disabled={isDisabled} className='btn-solid w-100'>
                                    {isDisabled ? <Loader /> : "Logout"}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LogoutPopup