import React, { useContext, useRef, useState, useEffect } from 'react'
import CustomInput from '../forms/CustomInput'
import Loader from '../loader/Loader'
import EditProfilePopup from '../popup/EditProfilePopup'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import Assets from '../../constants/images'
import { success, error } from '../../constants/msg'
import { EyeIcon } from '../../constants/svgs'
import { ChangePassword } from '../../services/auth/auth'
import { GetTermsAndConditions } from '../../services/pages/pages'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { UserData } from '../../App'
import { ToastContainer } from 'react-toastify'
import { SetTokenLocalStorage } from '../../services/localStorage/localStorage'

const ChangePasswordForm = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [isDisabled, setIsDisabled] = useState(false)
    const password = watch("new_password")
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const res = await ChangePassword(data)
            reset({
                [FIELD.old_password]: "",
                [FIELD.new_password]: "",
                [FIELD.confirm_password]: "",
            })
            SetTokenLocalStorage(res.data.data.token)
            success(res.data.message)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    return (
        <div className='change-password-form'>


            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {/* CURRENT PASSWORD INPUT */}
                    <Col xs={12} sm={8} md={10} lg={7} className="mb-4">
                        <Controller
                            control={control}
                            name={FIELD.old_password}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CURRENT_PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                            }}
                            render={({ field }) => (
                                <div className='password-wrapper'>
                                    <CustomInput
                                        {...field}
                                        isDisabled={false}
                                        controlId={FIELD.old_password}
                                        name={FIELD.old_password}
                                        placeholder="Current Password *"
                                        type={showCurrentPassword ? "text" : "password"}
                                        label="Current Password *"
                                        styles=""
                                    />
                                    <span className="eye-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)}><EyeIcon /></span>
                                </div>
                            )}
                        />
                        {errors[FIELD.old_password] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.old_password].message}</small>}
                    </Col>

                    {/* NEW PASSWORD INPUT */}
                    <Col xs={12} sm={8} md={10} lg={7} className="mb-4">
                        <Controller
                            control={control}
                            name={FIELD.new_password}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.NEW_PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                            }}
                            render={({ field }) => (
                                <div className='password-wrapper'>
                                    <CustomInput
                                        {...field}
                                        isDisabled={false}
                                        controlId={FIELD.new_password}
                                        name={FIELD.new_password}
                                        type={showNewPassword ? "text" : "password"}
                                        placeholder="New Password *"
                                        label="New Password *"
                                        styles=""
                                    />
                                    <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}><EyeIcon /></span>
                                </div>
                            )}
                        />
                        {errors[FIELD.new_password] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.new_password].message}</small>}
                    </Col>

                    {/* CONFIRM PASSWORD INPUT */}
                    <Col xs={12} sm={8} md={10} lg={7} className="mb-4">
                        <Controller
                            control={control}
                            name={FIELD.confirm_password}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                validate: (value) =>
                                    value === password || "The Passwords do not match"
                            }}
                            render={({ field }) => (
                                <div className='password-wrapper'>
                                    <CustomInput
                                        {...field}
                                        isDisabled={false}
                                        controlId={FIELD.confirm_password}
                                        name={FIELD.confirm_password}
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="Confirm Password *"
                                        label="Confirm Password *"
                                        styles=""
                                    />
                                    <span className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}><EyeIcon /></span>
                                </div>
                            )}
                        />
                        {errors[FIELD.confirm_password] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.confirm_password].message}</small>}
                    </Col>

                    <Col xs={12} sm={8} md={10} lg={7} className="mt-2 text-end">
                        <Button type="submit" className='btn-solid'>
                            {isDisabled ? <Loader /> : "Save"}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ChangePasswordForm