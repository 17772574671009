import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import AddSetupPostInformationPopup from '../components/popup/AddSetupPostInformationPopup'
import AddVacationAllotmentPopup from '../components/popup/AddVacationAllotmentPopup'
import EditVacationAllotmentPopup from '../components/popup/EditVacationAllotmentPopup'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { BulkDeleteVacation, DeleteVacation, GetVacations } from '../services/vacations/vacations'
import { useForm } from 'react-hook-form'
import { error, success } from '../constants/msg'

const VacationAllotmentPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [addVacationAllotmentPopup, setAddVacationAllotmentPopup] = useState(false)
    const [editVacationAllotmentPopup, setEditVacationAllotmentPopup] = useState(false)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([
        {
            name: 'Years of Services',
            selector: rowData => rowData.years_of_service,
            sortable: true,
        },
        {
            name: 'Vacations (Weeks)',
            selector: rowData => rowData.duration,
            sortable: true,
        },
        {
            name: 'Site',
            selector: rowData => rowData.site,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="" row={row} rowData={rowData} setEditPopup={setEditVacationAllotmentPopup} setSelectedRow={setSelectedRow} viewAction={false} />
        },
    ])

    const getVacations = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
            }
            const res = await GetVacations(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    years_of_service: item?.years_of_service || "-",
                    duration: item?.duration.toString() || "-",
                    site: item?.site_name || "-"
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e?.response?.data?.message)
        }
    }

    const deleteVacation = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteVacation(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteVacation(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getVacations()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        getVacations()
    }, [page.currentPage, addVacationAllotmentPopup])

    return (
        <>
            <AddVacationAllotmentPopup addVacationAllotmentPopup={addVacationAllotmentPopup} setAddVacationAllotmentPopup={setAddVacationAllotmentPopup} rows={rows} setRows={setRows} />
            <EditVacationAllotmentPopup editVacationAllotmentPopup={editVacationAllotmentPopup} setEditVacationAllotmentPopup={setEditVacationAllotmentPopup} getVacations={getVacations} selectedRow={selectedRow} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteVacation} />


            <div className='vacation-allotment-page'>
                <Container className='p-0'>
                    <Row>
                        <Col xs={12} sm={8} md={6} className="pt-2">
                            <h1>Vacation Allotment</h1>
                        </Col>

                        <Col xs={12} sm={4} md={6} className="">
                            <Row className="justify-content-end">
                                <Col xs={12} sm={12} md={8} lg={4} xl={3} className="mb-4">
                                    <Button onClick={() => setAddVacationAllotmentPopup(true)} className='mt-4 mt-sm-0 w-100 btn-solid'>+ Add New</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col xs={12}>
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default VacationAllotmentPage