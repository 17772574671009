import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { FIELD, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomDatePicker from '../forms/CustomDatePicker'
import CustomStatusSelect from '../forms/CustomStatusSelect'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import CustomUserSelect from '../forms/CustomUserSelect'
import { ToastContainer } from 'react-toastify'
import ClearFormPopup from "../popup/ClearFormPopup"
import { AddCalendarEvent, AddLongTermEvent } from '../../services/calendar-events/calendar-events'
import { CleanAndFormatData } from '../../utils/helper'
import { error, success } from '../../constants/msg'
import Loader from '../loader/Loader'
import CustomEventTypeSelect from '../forms/CustomEventTypeSelect'
import CustomScheduleSelect from '../forms/CustomScheduleSelect'
import { useRef } from 'react'

const AddLongTermEventPopup = ({ addLongTermEventPopup, setAddLongTermEventPopup, getCalendarEvents }) => {
    const { register, handleSubmit, watch, reset, setError, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const startDate = watch(`${FIELD.start_date}`)
    const endDate = watch(`${FIELD.end_date}`)
    const [onChecked, setOnChecked] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null, start_date: null, end_date: null, event: null, schedule: null
    })

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.team]: null,
            [FIELD.user]: null,
            [FIELD.event]: null,
            [FIELD.schedule]: null,
            [FIELD.ot]: null,
            [FIELD.start_date]: null,
            [FIELD.end_date]: null,
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null, date: null, event: null, schedule: null })
        setOnChecked(false)
        setClearFormPopup(false)
    }

    const onSubmit = async (data) => {
        const isError = validateStartAndEndData()
        if (!isError) {
            setIsDisabled(true)

            const formData = CleanAndFormatData(data)
            formData[FIELD.event_status] = "0"
            // if formData has schedule and schedule=3 than set ot = 'false' otherwise set ot = 'checkbox value'
            if (Object.hasOwn(formData, FIELD.schedule)) {
                if (formData.schedule.value == 3) {
                    formData[FIELD.ot] = false
                }
                else {
                    formData[FIELD.ot] = onChecked
                }
            }

            try {
                const res = await AddLongTermEvent(formData)
                getCalendarEvents()
                success(res.data.message)
                setAddLongTermEventPopup(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsDisabled(false)
                error(e?.response?.data?.message)
            }
        }
    }

    const handleShowOtCheckbox = () => {
        const scheduleValue = getValues(`${FIELD.schedule}`)
        const eventTypeValue = getValues(`${FIELD.event}`)

        if ((scheduleValue?.value === 1 || scheduleValue?.value === 2) && eventTypeValue?.value === 2) {
            // if schedule value is 1 or 2 
            return "d-block"
        }
        else {
            return "d-none"
        }
    }

    const handleShowScheduleSelect = () => {
        const value = getValues(`${FIELD.event}`)
        if (value?.value === 2) {
            // if event is selected
            return "d-block"
        }
        else {
            return "d-none"
        }
    }

    const validateStartAndEndData = () => {
        let isError = false
        if (startDate && endDate) {
            if (startDate >= endDate) {
                isError = true
                setError(`${FIELD.start_date}`, { message: VALIDATIONS_TEXT.START_DATE })
            }
            else {
                setError(`${FIELD.start_date}`, null)
            }
        }
        return isError
    }

    useEffect(() => {
        // clear form when popup open or close and set checkbox value to false
        clearForm()
    }, [addLongTermEventPopup])

    useEffect(() => {
        validateStartAndEndData()
    }, [startDate, endDate])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />


            <Modal
                className='theme-popup add-long-term-event-popup'
                show={addLongTermEventPopup}
                onHide={() => setAddLongTermEventPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add Long Term Event</h1>
                                        <span className='times-icon' onClick={() => setAddLongTermEventPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>

                                    {/* TEAM SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.team}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.TEAM_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTeamSelect
                                                    {...field}
                                                    name={FIELD.team}
                                                    placeholder={"Select Team *"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.team] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team].message}</small>}
                                    </Col>

                                    {/* USER SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.user}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.USER_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomUserSelect
                                                    {...field}
                                                    name={FIELD.user}
                                                    placeholder={"Select User *"}
                                                    styles=""
                                                    isDependent={true}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.user] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.user].message}</small>}
                                    </Col>

                                    {/* EVENT TYPE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.event}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EVENT_TYPE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomEventTypeSelect
                                                    {...field}
                                                    name={FIELD.event}
                                                    placeholder={"Select Event Type *"}
                                                    styles=""
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.event] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.event].message}</small>}
                                    </Col>

                                    {/* SCHEDULE SELECT */}
                                    <Col xs={12} className={`mb-4 ${handleShowScheduleSelect()}`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.schedule}
                                            rules={{
                                                required: {
                                                    value: getValues(`${FIELD.event}`)?.value === 2 ? true : false,
                                                    message: VALIDATIONS_TEXT.SCHEDULE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomScheduleSelect
                                                    {...field}
                                                    name={FIELD.schedule}
                                                    placeholder={"Select Schedule *"}
                                                    styles=""
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.schedule] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.schedule].message}</small>}
                                    </Col>

                                    {/* OT WEEKEND CHECKBOX */}
                                    <Col xs={12} className={`mb-4 ${handleShowOtCheckbox()} `}>
                                        <Form.Check
                                            checked={onChecked}
                                            type="checkbox"
                                            id={FIELD.ot}
                                            onChange={(e) => setOnChecked(!onChecked)}
                                            label="Allow OT for weekends"
                                        />
                                    </Col>

                                    {/* START DATE PICKER */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.start_date}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.START_DATE_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    {...field}
                                                    name={FIELD.start_date}
                                                    placeholder={"Select Start Date *"}
                                                    isClearable={true}
                                                    isDependent={true}
                                                    isDisabled={!getValues(`${[FIELD.user]}`) ? true : false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.start_date] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.start_date].message}</small>}
                                    </Col>

                                    {/* END DATE PICKER */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.end_date}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.END_DATE_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    {...field}
                                                    name={FIELD.end_date}
                                                    placeholder={"Select End Date *"}
                                                    isClearable={true}
                                                    isDependent={true}
                                                    isDisabled={!getValues(`${[FIELD.user]}`) ? true : false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.end_date] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.end_date].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button type='submit' disabled={isDisabled} className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddLongTermEventPopup 