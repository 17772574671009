import React from 'react'
import Select from 'react-select'
import { FIELD } from '../../constants/app-constants'

const CustomSelect = (props) => {
    const handleChange = (value) => {
        if (props.name === FIELD.role) {
            if (value) {
                props.setState({
                    ...props.state,
                    role: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    role: null
                })
            }
        }
        else if (props.name === FIELD.site) {
            if (value) {
                props.setState({
                    ...props.state,
                    site: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    site: null
                })
            }
        }
        else if (props.name === FIELD.department) {
            if (value) {
                props.setState({
                    ...props.state,
                    department: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    department: null
                })
            }
        }
        else if (props.name === FIELD.team) {
            if (value) {
                props.setState({
                    ...props.state,
                    team: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    team: null
                })
            }
        }
        else if (props.name === FIELD.user) {
            if (value) {
                props.setState({
                    ...props.state,
                    user: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    user: null
                })
            }
        }
        else if (props.name === FIELD.recent_events) {
            if (value) {
                props.setState({
                    ...props.state,
                    recent_events: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    recent_events: null
                })
            }
        }
        else if (props.name === FIELD.event) {
            if (value) {
                props.setState({
                    ...props.state,
                    event: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    event: null
                })
            }
        }
        else if (props.name === FIELD.schedule) {
            if (value) {
                props.setState({
                    ...props.state,
                    schedule: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    schedule: null
                })
            }
        }
        else if (props.name === FIELD.competency) {
            if (value) {
                props.setState({
                    ...props.state,
                    competency: value
                })
            }
            else {
                props.setState({
                    ...props.state,
                    competency: null
                })
            }
        }
    }

    return (
        <Select
            {...props}
            className={`react-select-container w-100 ${props.styles && props.styles}`}
            classNamePrefix="react-select"
            name={props.name}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled}
            isLoading={props.isLoading}
            isClearable={props.isClearable}
            onChange={(value) => {
                props.onChange(value)
                handleChange(value)
            }}
            options={props.options}
        />
    )
}

export default CustomSelect