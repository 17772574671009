import React, { useState, useEffect } from 'react'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { roles_options } from '../../utils/constants'
import { FIELD } from '../../constants/app-constants'

const CustomRoleSelect = (props) => {
    const [options, setOptions] = useState(roles_options)

    useEffect(() => {
        if (!props.state.role) {
            // If no role is selected than clear site,department,team,user select 
            if(!props.styles){
                // trigger only when you are not using filters select (styles only come with filters selectbox)
                props.setValue(`${FIELD.site}`, null)
            }
            props.setValue(`${FIELD.department}`, null)
            props.setValue(`${FIELD.team}`, null)
            props.setValue(`${FIELD.user}`, null)
        }
    }, [props.state.role])

    return (
        <CustomSelect
            {...props}
            field={props.field}
            name={props.name}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled}
            isLoading={props.isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomRoleSelect