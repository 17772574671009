import React, { useState, useContext, useEffect } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { EyeIcon, LockIcon } from '../../constants/svgs'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify'
import { UserData, UserRole } from '../../App'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../forms/CustomInput'
import Assets from '../../constants/images'
import { ResetPassword } from '../../services/auth/auth'
import { error, success } from '../../constants/msg'

const ResetPasswordForm = () => {
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const password = watch("new_password")
    const navigate = useNavigate()
    const location = useLocation()
    const [isDisabled, setIsDisabled] = useState(false)
    const [userData, setUserData] = useContext(UserData)
    const [userRole, setUserRole] = useContext(UserRole)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            const apiData = {
                email: location.state.data.email,
                code: location.state.data.code,
                new_password: data.new_password
            }
            const token = location.state.data.token
            const res = await ResetPassword(apiData, token)
            setIsDisabled(false)
            success(res.data.message)
            setTimeout(() => {
                navigate("/signin")
            }, 1500);
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    return (
        <div className='auth-card reset-password-form'>


            <div className="logo-wrapper mb-30 d-block d-xl-none">
                <img src={Assets.Logo} alt="" />
            </div>

            <div className='header'>
                <NavLink className="text-grey d-block mb-2 w-400" to="/forgot-password">Back</NavLink>
                <h1>Reset Password</h1>
                <p className='mt-2'>Your new password must be different from old password.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    {/* NEW PASSWORD INPUT */}
                    <div className="mt-20">
                        <Controller
                            control={control}
                            name={FIELD.new_password}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.NEW_PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                            }}
                            render={({ field }) => (
                                <div className='password-wrapper'>
                                    <CustomInput
                                        {...field}
                                        isDisabled={false}
                                        controlId={FIELD.new_password}
                                        name={FIELD.new_password}
                                        placeholder="New Password *"
                                        type={showNewPassword ? "text" : "password"}
                                        label="New Password *"
                                        styles=""
                                    />
                                    <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}><EyeIcon /></span>
                                </div>
                            )}
                        />
                        {errors[FIELD.new_password] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.new_password].message}</small>}
                    </div>

                    {/* CONFIRM PASSWORD INPUT */}
                    <div className="mt-20">
                        <Controller
                            control={control}
                            name={FIELD.confirm_password}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                                },
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                validate: (value) =>
                                    value === password || "The Passwords do not match"
                            }}
                            render={({ field }) => (
                                <div className='password-wrapper'>
                                    <CustomInput
                                        {...field}
                                        isDisabled={false}
                                        controlId={FIELD.confirm_password}
                                        name={FIELD.confirm_password}
                                        placeholder="Confirm Password *"
                                        type={showConfirmPassword ? "text" : "password"}
                                        label="Confirm Password *"
                                        styles=""
                                    />
                                    <span className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}><EyeIcon /></span>
                                </div>
                            )}
                        />
                        {errors[FIELD.confirm_password] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.confirm_password].message}</small>}
                    </div>

                    <Button type="submit" className='w-100 btn-solid mt-40'>
                        {
                            isDisabled ? <Loader /> : "Update"
                        }
                    </Button>

                    <div className="footer border-top pt-30 mt-30">
                        <p className='w-300'>
                            Protected by recaptcha and subject to the WTT
                            <NavLink to="/privacy-policy"> Privacy Policy </NavLink>
                            and <NavLink to="/terms-and-conditions"> Terms of Service</NavLink>.
                        </p>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ResetPasswordForm