import React, { useContext } from "react";
import { Container, Row, Col, FloatingLabel, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { UserRole } from "../../App";
import { roles } from "../../utils/constants";
import { HandleShow } from "../../utils/helper";

const SetupPageLayout = ({ children }) => {
  const [userRole, setUserRole] = useContext(UserRole);

  return (
    <div className="setup-page-layout">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="link-wrapper">
              <Row>
                <Col xs={12} sm={10} lg={11} xl={9}>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
                      <NavLink
                        to="/setup/set-work-schedule"
                        className={`btn-outline w-100 ${HandleShow([roles.super_user, roles.site_admin, roles.supervisor], userRole)}`}
                      >
                        Set Work Schedule
                      </NavLink>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
                      <NavLink
                        to="/setup/post-information"
                        className={`btn-outline w-100 ${HandleShow([roles.super_user], userRole)}`}
                      >
                        Setup Post Information
                      </NavLink>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
                      <NavLink
                        to="/setup/item-information"
                        className={`btn-outline w-100 ${HandleShow([roles.super_user], userRole)}`}
                      >
                        Off Item Information
                      </NavLink>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
                      <NavLink
                        to="/setup/vacation-allotment"
                        className={`btn-outline w-100 ${HandleShow([roles.super_user], userRole)}`}
                      >
                        Vacation Allotment
                      </NavLink>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs={12} className="mt-4">
                  {children}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SetupPageLayout;
