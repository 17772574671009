import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import CustomInput from '../forms/CustomInput'
import { FIELD, KEYS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomRoleSelect from '../forms/CustomRoleSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import { CleanAndFormatData, HandleRegister, HandleShow } from '../../utils/helper'
import { roles } from '../../utils/constants'
import CustomUserSelect from '../forms/CustomUserSelect'
import ClearFormPopup from "../popup/ClearFormPopup"
import { ToastContainer } from 'react-toastify'
import { AddUser, GetUsers } from "../../services/users/users"
import { success, error } from '../../constants/msg'
import Loader from '../loader/Loader'

const AddUserPopup = ({ addUserPopup, setAddUserPopup, rows, setRows }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        try {
            const res = await AddUser(formData)
            const obj = {
                id: res?.data?.data?.id || "-",
                full_name: res?.data?.data?.full_name || "-",
                team: res?.data?.data?.team_name || "-",
                email: res?.data?.data?.email || "-",
                role: res?.data?.data?.role_name || "-"
            }
            setRows([obj, ...rows])
            success(res.data.message)
            setAddUserPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    const clearForm = () => {
        reset({
            [FIELD.full_name]: "",
            [FIELD.email]: "",
            [FIELD.role]: null,
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.team]: null,
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null })
        setClearFormPopup(false)
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [addUserPopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />


            <Modal
                className='theme-popup add-user-popup'
                show={addUserPopup}
                onHide={() => setAddUserPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add User</h1>
                                        <span className='times-icon' onClick={() => setAddUserPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* FULL NAME INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.full_name}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.NAME,
                                                    message: VALIDATIONS_TEXT.NAME_MAX
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.full_name}
                                                    name={FIELD.full_name}
                                                    placeholder="Full Name *"
                                                    type="text"
                                                    label="Full Name *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.full_name] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.full_name].message}</small>}
                                    </Col>

                                    {/* EMAIL INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.email}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.email}
                                                    name={FIELD.email}
                                                    placeholder="Email *"
                                                    type="email"
                                                    label="Email *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.email] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.email].message}</small>}
                                    </Col>

                                    {/* ROLE SELECT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.role}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.ROLE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomRoleSelect
                                                    {...field}
                                                    name={FIELD.role}
                                                    placeholder={"Select Role *"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.role] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.role].message}</small>}
                                    </Col>

                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4 ${HandleShow([roles.site_admin, roles.supervisor, roles.employee], selectedData?.role?.value)}`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: HandleRegister([roles.site_admin, roles.supervisor, roles.employee], selectedData?.role?.value),
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} className={`mb-4 ${HandleShow([roles.supervisor, roles.employee], selectedData?.role?.value)}`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: HandleRegister([roles.supervisor, roles.employee], selectedData?.role?.value),
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>

                                    {/* TEAM SELECT */}
                                    <Col xs={12} className={`mb-4 ${HandleShow([roles.supervisor, roles.employee], selectedData?.role?.value)}`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.team}
                                            rules={{
                                                required: {
                                                    value: HandleRegister([roles.supervisor, roles.employee], selectedData?.role?.value),
                                                    message: VALIDATIONS_TEXT.TEAM_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTeamSelect
                                                    {...field}
                                                    name={FIELD.team}
                                                    placeholder={"Select Team *"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.team] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddUserPopup