import React, { useState, useEffect, useContext } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import { Controller, useForm } from 'react-hook-form'
import { success, error } from '../constants/msg'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import { ApproveEvent, BulkDeleteCalendarEvent, DeleteCalendarEvent, GetCalendarEvents, GetUpcomingEvents, RejectEvent } from '../services/calendar-events/calendar-events'
import ThemeStatus from '../components/dashboard/ThemeStatus'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import CustomSiteSelect from '../components/forms/CustomSiteSelect'
import CustomRecentEventSelect from '../components/forms/CustomRecentEventSelect'
import Loader from '../components/loader/Loader'
import ThemeChart from '../components/dashboard/ThemeChart'
import { GetBackgroundColor } from '../utils/helper'
import { UserRole } from '../App'
import { roles } from '../utils/constants'

const CalendarEventsPage = () => {
    const { watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const site_chart = watch(FIELD.site_chart)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [rows, setRows] = useState([]);
    const [userRole, setUserRole] = useContext(UserRole);
    console.log("userRole", userRole)
    const [columns, setColumns] = useState([
        {
            name: 'Date ',
            selector: rowData => rowData.date,
            sortable: true,
        },
        {
            name: 'Event Name',
            selector: rowData => rowData.event_name,
            sortable: true,
        },
        {
            name: 'User Name',
            selector: rowData => rowData.user_name,
            sortable: true,
        },
        {
            name: 'Status',
            selector: rowData => rowData.status,
            cell: (rowData, row) => (
                rowData?.can_approve ?
                    <div className="d-flex align-items-center">
                        <Button onClick={() => approveEvent(rowData?.id)} className="btn-success approved-btn">Approved</Button>
                        <Button onClick={() => rejectEvent(rowData?.id)} className="mx-2 btn-danger reject-btn">Reject</Button>
                    </div>
                    :
                    <ThemeStatus status={rowData} />
            )
        },
    ])
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null, recent_events: null
    })
    const [addLongTermEventPopup, setAddLongTermEventPopup] = useState(false)
    const [addCalendarEventPopup, setAddCalendarEventPopup] = useState(false)
    const [upcomingEventsData, setUpcomingEventData] = useState([])
    const [isChartLoading, setIsChartLoading] = useState(true)
    const [labels, setLabels] = useState([])
    const [datasets, setDatasets] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const getCalendarEvents = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(search && { search }),
                ...(selectedData.site && { site_id: selectedData.site.value }),
                ...(selectedData.recent_events && { ...selectedData.recent_events?.name === "month" ? { month: selectedData.recent_events.value } : selectedData.recent_events?.name === "recent_events" ? { recent_events: true } : { event_status: selectedData.recent_events.value } })
            }
            const res = await GetCalendarEvents(params)
            const data = res.data

            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    date: item?.date || "-",
                    event_name: item?.event_display || "-",
                    user_name: item?.full_name || "-",
                    status: item?.status?.toString() || "-",
                    can_approve: item?.can_approve,
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e?.response?.data?.message)
        }
    }

    const deleteCalendarEvent = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteCalendarEvent(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteCalendarEvent(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getCalendarEvents()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    const generateLabelsAndDatasets = (data) => {
        let labels = []
        let datasets = []
        let teams = []

        data[0].teams?.map((team) => {
            /*
                Generating schema for teams like 
                teams = [
                    { 
                        team_a : Team A,
                        data: [],
                        display_name: "Team A"
                    }
                ]
            */

            const key = team?.team_name.toLowerCase().split(" ").join("_")
            teams.push({
                [key]: team?.team_name,
                data: [],
                display_name: team?.team_name
            })
        })

        data?.forEach((item) => {
            labels.push(item?.date)
            /*
                For each dates push team data in their dataset
                teams = [
                    { 
                        team_a : Team A,
                        data: [1,2,5,6,7],
                        display_name: "Team A"
                    }
                ]
            */
            item?.teams?.forEach((team, i) => {
                teams[i].data.push(team?.events)
            })
        })

        teams?.forEach((team, index) => {
            /*
               Create dataset for each team
               teams = [
                   { 
                        label : Team A,
                        data: [1,2,5,6,7],
                        backgroundColor: "#45S2Ad"
                   }
               ]
           */
            datasets.push({
                label: team?.display_name,
                data: team?.data,
                backgroundColor: GetBackgroundColor(index),
            })
        })

        setLabels(labels)
        setDatasets(datasets)
    }

    const approveEvent = async (id) => {
        try {
            const res = await ApproveEvent(id)
            getCalendarEvents()
            success(res.data.message)
        }
        catch (e) {
            error(e?.response?.data?.message)
        }
    }

    const rejectEvent = async (id) => {
        try {
            const res = await RejectEvent(id)
            getCalendarEvents()
            success(res.data.message)
        }
        catch (e) {
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        getCalendarEvents()
    }, [page.currentPage, selectedData, search])

    useEffect(() => {
        const getUpcomingEvents = async () => {
            setIsChartLoading(true)
            try {
                const params = {
                    ...(site_chart && { site_id: site_chart?.value })
                }
                const res = await GetUpcomingEvents(params)
                const data = res.data.data
                generateLabelsAndDatasets(data)
                setUpcomingEventData(data)
                setIsChartLoading(false)
            }
            catch (e) {
                setIsChartLoading(false)
                error(e?.response?.data?.message)
            }
        }

        getUpcomingEvents()
    }, [site_chart])

    return (
        <>
            <AddLongTermEventPopup addLongTermEventPopup={addLongTermEventPopup} setAddLongTermEventPopup={setAddLongTermEventPopup} getCalendarEvents={getCalendarEvents} />
            <AddCalendarEventPopup addCalendarEventPopup={addCalendarEventPopup} setAddCalendarEventPopup={setAddCalendarEventPopup} rows={rows} setRows={setRows} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteCalendarEvent} />


            <div className='pages calendar-events-page'>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={4} className="pt-2">
                            <h1>Calendar Events</h1>
                        </Col>

                        <Col xs={12} sm={12} md={8} className="mt-sm-4 mt-md-0">
                            <Row className="justify-content-end">
                                {userRole === roles.employee ? null : <Col xs={12} sm={6} md={6} lg={5} xl={4} className="mb-4">
                                    <Button onClick={() => setAddLongTermEventPopup(true)} className='mt-4 mt-sm-0 w-100 btn-solid'>+ Add Long Term Events</Button>
                                </Col>}


                                <Col xs={12} sm={6} md={6} lg={5} xl={4} className="mb-4">
                                    <Button onClick={() => setAddCalendarEventPopup(true)} className='w-100 btn-solid'>+ Add Calendar Event</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-0 mt-md-4">
                        <Col xs={12} lg={4} className="mb-4">
                            <CustomSearchInput
                                search={search}
                                onChange={(e) => setSearch(e.target.value)}
                                label="Search"
                                placeholder="Search"
                            />
                        </Col>

                        <Col xs={12} lg={8} className="mb-4">
                            <Row className="justify-content-end">
                                {/* SITE FILTER SELECT */}
                                <Col xs={12} sm={4} md={4} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.site}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomSiteSelect
                                                {...field}
                                                name={FIELD.site}
                                                placeholder={"Select Site"}
                                                styles="small-select"
                                                isClearable={true}
                                                isPreFilled={false}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                {/* RECENT ACTIVITY FILTER SELECT */}
                                <Col xs={12} sm={4} md={4} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.recent_events}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.RECENT_EVENT_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomRecentEventSelect
                                                {...field}
                                                name={FIELD.recent_events}
                                                placeholder={"Select Filter"}
                                                styles="small-select"
                                                isClearable={true}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                    {errors[FIELD.recent_events] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.recent_events].message}</small>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mb-4">
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="chart-wrapper">
                                <Row>
                                    <Col xs={12} sm={5} lg={4} className="pt-2">
                                        <h1>Calendar Events</h1>
                                    </Col>

                                    <Col xs={12} sm={7} lg={8} className="mt-4 mt-sm-0">
                                        <Row className="justify-content-end">
                                            {/* SITE CHART FILTER SELECT */}
                                            <Col xs={12} sm={6} md={8} lg={8} xl={4} className="mb-4">
                                                <Controller
                                                    control={control}
                                                    name={FIELD.site_chart}
                                                    rules={{
                                                        required: {
                                                            value: false,
                                                            message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                        }
                                                    }}
                                                    render={({ field }) => (
                                                        <CustomSiteSelect
                                                            {...field}
                                                            name={FIELD.site_chart}
                                                            placeholder={"Select Site"}
                                                            styles="small-select"
                                                            isClearable={true}
                                                            isPreFilled={false}
                                                            state={selectedData}
                                                            setState={setSelectedData}
                                                            setValue={setValue}
                                                            getValues={getValues}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        {
                                            isChartLoading ?
                                                <div className="text-center"><Loader /></div>
                                                :
                                                upcomingEventsData?.length > 0 ?
                                                    <>
                                                        <div className='text-center mb-4'>
                                                            <h2>
                                                                <span><b>{upcomingEventsData[0]?.date}</b></span>
                                                                <span className='mx-2 text-blue'>to</span>
                                                                <span><b>{upcomingEventsData[upcomingEventsData.length - 1]?.date}</b></span>
                                                            </h2>
                                                        </div>

                                                        <ThemeChart labels={labels} datasets={datasets} />
                                                    </>
                                                    :
                                                    <div className="text-center"><h1>No Data Available</h1></div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CalendarEventsPage