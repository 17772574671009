import React from 'react'
import { AiFillEdit } from "react-icons/ai"
import { AiFillEye } from "react-icons/ai"
import { NavLink } from 'react-router-dom'

const ThemeDataTableAction = (props) => {
    return (
        <div className='themedata-table-action'>
            <div className="d-flex align-items-center">
                <span onClick={() => {
                    props.setEditPopup(true)
                    props.setSelectedRow({ ...props.rowData, index: props.row })
                }}
                >
                    <AiFillEdit className='icons' />
                </span>

                <span className={props.viewAction ? "ms-3 d-block" : "d-none"}>
                    <NavLink to={`${props.link}/${props.rowData.id}`}><AiFillEye className='icons' /></NavLink>
                </span>
            </div>
        </div>
    )
}

export default ThemeDataTableAction