import React, { useState, useEffect } from 'react'
import { GetSingleTeam, GetTeams, GetUsersByTeam } from '../../services/teams/teams'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'

const CustomCompetencyUserSelect = (props) => {
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const department = props.getValues(`${FIELD.department}`)
    const team = props.getValues(`${FIELD.team}`)

    useEffect(() => {
        // Fetch users by team
        const getTeams = async (params) => {
            setIsLoading(true)
            const options = []
            try {
                const res = await GetTeams(params)
                const data = res.data.data
                data[0]?.members?.forEach(item => {
                    options.push({
                        label: item?.full_name,
                        value: item?.user_profile
                    })
                })
                setOptions(options)
                setIsLoading(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsDisabled(false)
                error(e?.response?.data?.message)
            }
        }

        // Fetch users only when team is selected
        if (props.state.team) {
            if (props.callFor === "ot_and_work_post_data") {
                const params = {
                    department_id: department?.value,
                    type: team?.value,
                    competency: true
                }
                getTeams(params)
            }
            else if (props.callFor === "competency_and_er_data") {
                const params = {
                    department_id: department?.value,
                    type: team?.value,
                    competency_er_data: true
                }
                getTeams(params)
            }
        }
    }, [props.state.team])

    useEffect(() => {
        if (props.isDependent) {
            if (!props.state.team) {
                // If no team is selected than clear user select 
                props.setValue(`${FIELD.user}`, null)
            }

            if (!props.getValues(`${FIELD.department}`) || !props.getValues(`${FIELD.site}`) || !props.getValues(`${FIELD.team}`)) {
                // Disabled the user if there is no team
                setIsDisabled(true)
            }
        }
    }, [props.state])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled ? true : isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomCompetencyUserSelect