import React, { useState, useEffect } from 'react'
import { GetDepartmentsBySite } from '../../services/departments/departments'
import CustomSelect from './CustomSelect'
import { error, success } from '../../constants/msg'
import { FIELD } from '../../constants/app-constants'
import { GetScheduleDetails, GetScheduleDetailsDates } from '../../services/schedule-details/schedule-detail'

const CustomScheduleDetailSelect = (props) => {
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        // Fetch schedule details dates by site and department
        const getScheduleDetailsDates = async () => {
            setIsLoading(true)

            const options = []
            try {
                const params = {
                    department_id: props?.state?.department?.value,
                    site_id: props?.state?.site?.value
                }
                const res = await GetScheduleDetailsDates(params)
                const data = res.data.data
                data?.forEach(item => {
                    options.push({
                        label: item?.start_date + " / " + item?.end_date,
                        value: item?.start_date + " / " + item?.end_date
                    })
                })
                setOptions(options)
                setIsLoading(false)
                setIsDisabled(false)
            }
            catch (e) {
                setIsLoading(false)
                setIsDisabled(false)
                error(e?.response?.data?.message)
            }
        }

        if (props?.state?.department) {
            // Fetch schedule details dates only when department is selected
            getScheduleDetailsDates()
        }
    }, [props.state.department])

    useEffect(() => {
        if (!props.getValues(`${FIELD.department}`)) {
            // Disabled the schedule_detail if there is no department clear Schedule_Detail select 
            props.setValue(`${FIELD.schedule_detail}`, null)
            setIsDisabled(true)
        }
    }, [props.state])

    return (
        <CustomSelect
            {...props}
            placeholder={props.placeholder}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={props.isClearable}
            options={options}
        />
    )
}

export default CustomScheduleDetailSelect