import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import {GetTokenLocalStorage} from "../localStorage/localStorage";

export const AddSchedule = (data) => api.post(`${constant.V1}${apiUrl.schedule}/`, data)
export const RerunSchedule = (id) => api.post(`${constant.V1}${apiUrl.schedule}/${id}${apiUrl.rerun}/`)
export const GetSchedules = (params) => api.get(`${constant.V1}${apiUrl.schedule}/`, { params })
export const GetSchedulesAvgOvertime = (params) => api.get(`${constant.V1}${apiUrl.schedule}${apiUrl.avg_overtime}/`, { params })
export const GetSchedulesShiftEfficiency = (params) => api.get(`${constant.V1}${apiUrl.schedule}${apiUrl.shift_efficiency}/`, { params })
export const DeleteSchedule = (id) => api.delete(`${constant.V1}${apiUrl.schedule}/${id}/`)
export const BulkDeleteSchedule = (ids) => api.delete(`${constant.V1}${apiUrl.schedule}${apiUrl.bulk_delete}/`, { data: { ids } })
export const DownloadLastSchedule = (params) => api.get(`${constant.V1}${apiUrl.schedule}${apiUrl.last_schedule}/`, { params })
export const UploadSchedule = (data) => axios.post(`${constant.V1}${apiUrl.schedule_upload}/`, data, {
    headers: {
    Authorization: 'token ' + GetTokenLocalStorage()
}
})