import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import { BsCloudArrowDownFill, BsCloudArrowUpFill } from 'react-icons/bs'
import AddUserPopup from '../components/popup/AddUserPopup'
import { BulkCreateUser, BulkDeleteUser, DeleteUser, DownloadUserSampleCsv, GetUsers } from '../services/users/users'
import { error, success } from '../constants/msg'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { HandleRegister } from '../utils/helper'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import CustomSiteSelect from '../components/forms/CustomSiteSelect'
import { useForm, Controller } from 'react-hook-form'
import CustomRoleSelect from '../components/forms/CustomRoleSelect'
import EditUserPopup from '../components/popup/EditUserPopup'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import UploadCsvPopup from '../components/popup/UploadCsvPopup'
import { saveAs } from "file-saver"

const ManageUsersPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [isUploadDisabled, setIsUploadDisabled] = useState(false)
    const [customError, setCustomError] = useState("")
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [addUserPopup, setAddUserPopup] = useState(false)
    const [editUserPopup, setEditUserPopup] = useState(false)
    const [uploadCsvPopup, setUploadCsvPopup] = useState(false)
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([
        {
            name: 'User ID',
            selector: rowData => rowData.id,
            sortable: true,
        },
        {
            name: 'Full Name ',
            selector: rowData => rowData.full_name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: rowData => rowData.email,
            sortable: true,
        },
        {
            name: 'Team',
            selector: rowData => rowData.team,
            sortable: true,
        },
        {
            name: 'Role',
            selector: rowData => rowData.role,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="" row={row} rowData={rowData} setEditPopup={setEditUserPopup} setSelectedRow={setSelectedRow} viewAction={false} />
        },
    ])
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const getUsers = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(search && { search }),
                ...(selectedData.role && { role: selectedData.role.value }),
                ...(selectedData.site && { site_id: selectedData.site.value })
            }
            const res = await GetUsers(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    full_name: item?.full_name || "-",
                    team: item?.team_name || "-",
                    email: item?.email || "-",
                    role: item?.role_name || "-",
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e?.response?.data?.message)
        }
    }

    const deleteUser = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeleteUser(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeleteUser(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getUsers()

        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    const bulkCreateUser = async (formData) => {
        setIsUploadDisabled(true)
        setCustomError("")
        try {
            const res = await BulkCreateUser(formData)
            setUploadCsvPopup(false)
            setIsUploadDisabled(false)
            setCustomError("")
            success(res.data.message)
        }
        catch (e) {
            setIsUploadDisabled(false)
            setCustomError(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        getUsers()
    }, [page.currentPage, selectedData, search])

    return (
        <>
            <AddUserPopup addUserPopup={addUserPopup} setAddUserPopup={setAddUserPopup} rows={rows} setRows={setRows} />
            <EditUserPopup editUserPopup={editUserPopup} setEditUserPopup={setEditUserPopup} selectedRow={selectedRow} getUsers={getUsers} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deleteUser} />
            <UploadCsvPopup uploadCsvPopup={uploadCsvPopup} setUploadCsvPopup={setUploadCsvPopup} bulkCreateMethod={bulkCreateUser} isUploadDisabled={isUploadDisabled} customError={customError} setCustomError={setCustomError} />


            <div className='pages manage-users-page'>
                <Container>
                    <Row>
                        <Col xs={12} className="pt-2">
                            <h1>User Management</h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} lg={4} className="mb-4">
                            <CustomSearchInput
                                search={search}
                                onChange={(e) => setSearch(e.target.value)}
                                label="Search"
                                placeholder="Search"
                            />
                        </Col>

                        <Col xs={12} lg={8}>
                            <Row className="justify-content-end">
                                {/* SITE FILTER SELECT */}
                                <Col xs={12} sm={4} md={4} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.site}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomSiteSelect
                                                {...field}
                                                name={FIELD.site}
                                                placeholder={"Select Site"}
                                                styles="small-select"
                                                isClearable={true}
                                                isPreFilled={false}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                {/* ROLE FILTER SELECT */}
                                <Col xs={12} sm={4} md={4} className="mb-4">
                                    <Controller
                                        control={control}
                                        name={FIELD.role}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.ROLE_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomRoleSelect
                                                {...field}
                                                name={FIELD.role}
                                                placeholder={"Select Role"}
                                                styles="small-select"
                                                isDisabled={false}
                                                isClearable={true}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col xs={12} sm={4} md={4} className="mb-4">
                                    <Button className='w-100 btn-solid' onClick={() => setAddUserPopup(true)} >+ Add New User</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mb-4">
                            <div className="text-end">
                                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Upload CSV</Tooltip>}>
                                    <span className='cloud-icons ms-4' onClick={() => setUploadCsvPopup(true)}><BsCloudArrowUpFill /></span>
                                </OverlayTrigger>
                            </div>
                        </Col>

                        <Col xs={12}>
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ManageUsersPage