import React, { useState, useContext, useEffect } from 'react'
import { Button, FloatingLabel, Form } from 'react-bootstrap'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Loader from '../loader/Loader'
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { EyeIcon, LockIcon } from '../../constants/svgs'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { ToastContainer } from 'react-toastify'
import { UserData, UserRole } from '../../App'
import { Controller, useForm } from 'react-hook-form'
import CustomInput from '../forms/CustomInput'
import OtpInput from 'react-otp-input'
import Assets from '../../constants/images'
import { ForgetPassword, VerifyOtp } from '../../services/auth/auth'
import { error, success } from '../../constants/msg'

const VerificationCodeForm = () => {
    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [counter, setCounter] = useState(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        let timer = counter > 0 && setInterval(() => {
            setCounter(counter - 1)
        }, 1000)

        return () => {
            clearInterval(timer)
        }
    }, [counter])

    const handleOtpChange = (otp) => {
        setOtp(otp)
        if (otp.length === 0) {
            setOtpError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setOtpError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setOtpError(false)
        }
    }

    const onSubmit = async () => {
        if (otp.length === 0) {
            setOtpError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setOtpError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setIsDisabled(true)
            try {
                const data = {
                    email: location.state.email,
                    code: otp,
                }
                const res = await VerifyOtp(data)
                data.token = res.data.data.token
                success(res.data.message)
                setIsDisabled(false)
                setTimeout(() => {
                    navigate("/reset-password", { state: { data: data } })
                }, 1500)
            }
            catch (e) {
                setIsDisabled(false)
                error(e?.response?.data?.message)
            }
        }
    }

    const resendCode = async () => {
        setCounter(30)
        try {
            const res = await ForgetPassword({ email: location.state.email })
            success(res.data.message)
        }
        catch (e) {
            error(e?.response?.data?.message)
        }
    }

    return (
        <div className='auth-card verification-code-form'>


            <div className="logo-wrapper mb-30 d-block d-xl-none">
                <img src={Assets.Logo} alt="" />
            </div>

            <div className='header'>
                <NavLink className="text-grey d-block mb-2 w-400" to="/forgot-password">Back</NavLink>
                <h1>Verification</h1>
                <p className='mt-2'>Enter the verification code sent on your email address.</p>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='mt-30'>
                    {/* VERIFICATION CODE INPUT */}
                    <div>
                        <OtpInput
                            className="otp-input"
                            value={otp}
                            onChange={(otp) => handleOtpChange(otp)}
                            numInputs={4}
                            isInputNum={true}
                            placeholder="----"
                            hasErrored={true}
                            focusStyle="focus"
                        />
                        {otpError && <small className='text-start d-block text-danger mt-1'>{errorMessage}</small>}

                        <Button type="submit" className='w-100 btn-solid mt-40'>
                            {
                                isDisabled ? <Loader /> : "Verify"
                            }
                        </Button>
                        {
                            counter > 0 ?
                                <span className='d-inline-block mt-2'>Resend in: &nbsp; <b className='timer'> 00:{counter < 10 && '0'}{counter}</b></span>
                                :
                                <span className='d-inline-block mt-2 mb-20' onClick={() => resendCode()}>Resend Code</span>
                        }
                    </div>

                    <div className="footer border-top pt-30 mt-30">
                        <p className='w-300'>
                            Protected by recaptcha and subject to the WTT
                            <NavLink to="/privacy-policy"> Privacy Policy </NavLink>
                            and <NavLink to="/terms-and-conditions"> Terms of Service</NavLink>.
                        </p>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default VerificationCodeForm