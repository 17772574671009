import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { AddVacation } from '../../services/vacations/vacations'
import ClearFormPopup from './ClearFormPopup'
import { ToastContainer } from 'react-toastify'
import Loader from '../loader/Loader'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import { FIELD, VALIDATIONS_TEXT } from '../../constants/app-constants'
import { error, success } from '../../constants/msg'
import CustomSearchInput from '../forms/CustomSearchInput'
import { CleanAndFormatData } from '../../utils/helper'
import CustomInput from '../forms/CustomInput'

const AddVacationAllotmentPopup = ({ addVacationAllotmentPopup, setAddVacationAllotmentPopup, rows, setRows, getVacationsFunc }) => {
    const { register, handleSubmit, watch, reset, getValues, setValue, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.years_of_service]: "",
            [FIELD.duration]: "",
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null })
        setClearFormPopup(false)
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        formData[FIELD.min_year] = formData[FIELD.years_of_service].split("-")[0]
        formData[FIELD.max_year] = formData[FIELD.years_of_service].split("-")[1]
        console.log("Add Vacation Allotment", formData)
        try {
            const res = await AddVacation(formData)
            const obj = {
                id: res?.data?.data?.id || "-",
                years_of_service: res?.data?.data?.years_of_service || "-",
                duration: res?.data?.data?.duration || "-",
            }
            setRows([obj, ...rows])
            success(res.data.message)
            setAddVacationAllotmentPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [addVacationAllotmentPopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />


            <Modal
                className='theme-popup add-vacation-allotment-popup'
                show={addVacationAllotmentPopup}
                onHide={() => setAddVacationAllotmentPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add New Vacation Allotment</h1>
                                        <span className='times-icon' onClick={() => setAddVacationAllotmentPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* YEARS OF SERVICES INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.years_of_service}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.YEARS_OF_SERVICES_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.years_of_service}
                                                    name={FIELD.years_of_service}
                                                    placeholder="Years of service (min-max) *"
                                                    type="text"
                                                    label="Years of service (min-max) *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.years_of_service] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.years_of_service].message}</small>}
                                    </Col>

                                    {/* WEEKS DURATION INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.duration}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DURATION_REQUIRED,
                                                },
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.duration}
                                                    name={FIELD.duration}
                                                    placeholder="Duration Weeks *"
                                                    type="number"
                                                    label="Duration Weeks *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.duration] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.duration].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button type="submit" disabled={isDisabled} className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddVacationAllotmentPopup