import React, { useContext, useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ToastContainer } from 'react-toastify'
import { UserData } from '../App'
import TermsAndConditions from '../components/dashboard/TermsAndConditions'
import CustomInput from '../components/forms/CustomInput'
import Loader from '../components/loader/Loader'
import EditProfilePopup from '../components/popup/EditProfilePopup'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import Assets from '../constants/images'
import { error } from '../constants/msg'
import { GetTermsAndConditions } from '../services/pages/pages'

const TermsAndContitionsPage = ({ children }) => {
    return (
        <div className='pages terms-and-conditions-page'>
            <Container>
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1>Settings</h1>
                    </Col>

                    <Col md={5} lg={4} xl={3} className="d-none d-md-block">
                        {children}
                    </Col>

                    <Col md={7} lg={8} xl={6}>
                        <div className="content-wrapper">
                            <div className="mb-4">
                                <Row>
                                    <Col xs={12}>
                                        <h1>Terms And Conditions</h1>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row>
                                    <Col xs={12}>
                                        <TermsAndConditions />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TermsAndContitionsPage