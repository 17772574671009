import api from "../index";
import { apiUrl, constant } from "../../utils/constants";

export const AddLongTermEvent = (data) => api.post(`${constant.V1}${apiUrl.long_term_event}/`, data)
export const AddCalendarEvent = (data) => api.post(`${constant.V1}${apiUrl.calendar_event}/`, data)
export const GetCalendarEvents = (params) => api.get(`${constant.V1}${apiUrl.calendar_event}/`, { params })
export const DeleteCalendarEvent = (id) => api.delete(`${constant.V1}${apiUrl.calendar_event}/${id}/`)
export const BulkDeleteCalendarEvent = (ids) => api.delete(`${constant.V1}${apiUrl.calendar_event}${apiUrl.bulk_delete}/`, { data: { ids } })

export const GetIsEmployeeWorking = (data) => api.post(`${constant.V1}${apiUrl.calendar_event}${apiUrl.is_employee_working}/`, data)
export const GetUpcomingEvents = (params) => api.get(`${constant.V1}${apiUrl.calendar_event}${apiUrl.upcoming_events}/`, { params })

export const ApproveEvent = (id) => api.post(`${constant.V1}${apiUrl.calendar_event}/${id}${apiUrl.approve}/`)
export const RejectEvent = (id) => api.post(`${constant.V1}${apiUrl.calendar_event}/${id}${apiUrl.reject}/`)
