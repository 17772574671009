import React, { useState, useEffect } from 'react'
import { error } from '../../constants/msg'
import { GetPrivacyPolicy } from '../../services/pages/pages'
import Loader from '../loader/Loader'
import { Interweave } from "interweave";

const PrivacyPolicy = () => {
    const [data, setData] = useState("")
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const getPrivacyPolicy = async () => {
            setIsLoading(true)
            try {
                const res = await GetPrivacyPolicy()
                setData(res?.data?.data?.content)
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                error(e?.response?.data?.message)
            }
        }

        getPrivacyPolicy()
    }, [])

    return (
        <>
            {
                isLoading ?
                    <Loader />
                    :
                    <p>
                        <Interweave content={data} />
                    </p>
            }
        </>
    )
}

export default PrivacyPolicy