import React, { useState, useEffect, useRef } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { Bar, Chart, Line } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const DashboardChart = (props) => {
    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y:{
                max:100
            }
        },
        plugins: {
            legend: {

                onClick:(evt,legendItem,legend)=>{
                    const index= legend.chart.data.labels.indexOf(legendItem.text);
                    legend.chart.toggleDataVisibility(index)
                    legend.chart.update()

                },
                display: true,
                labels: {
                    boxWidth: 20, // set the width of each legend item
                    generateLabels:(chart) =>{
                        return chart?.data?.labels?.map(
                            (label,index) => ({
                                text:label,
                                fillStyle:chart.data.datasets[0].backgroundColor[index],
                                hidden:false,

                            })
                        )
                    }
                }
            }
        },

    };
    const [data, setData] = useState({
        labels:[],
        datasets:[],
        backgroundColor:""
    })

    useEffect(()=>{
        if(props){
            setData({
                labels:props?.labels,
                datasets:props?.datasets
            })
        }
    },[props])



    return (
        <div className='theme-chart'>
            <Bar options={options} data={data} />
        </div>
    )
}

export default DashboardChart