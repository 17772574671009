import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import { BsCloudArrowDownFill, BsCloudArrowUpFill } from 'react-icons/bs'
import AddUserPopup from '../components/popup/AddUserPopup'
import AddSitePopup from '../components/popup/AddSitePopup'
import { useForm } from 'react-hook-form'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { error, success } from '../constants/msg'
import { BulkDeleteSite, DeleteSite, GetSingleSite, GetSites } from '../services/sites/sites'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import EditSitePopup from '../components/popup/EditSitePopup'
import { useParams } from 'react-router-dom'

const SiteDepartmentsDetailPage = () => {
    const { id } = useParams()
    const [site, setSite] = useState(null)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [rows, setRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [columns, setColumns] = useState([
        {
            name: 'Department ID',
            selector: rowData => rowData.id,
            sortable: true,
        },
        {
            name: 'Department',
            selector: rowData => rowData.department,
            sortable: true,
        },
        {
            name: 'Shift A',
            selector: rowData => rowData.team_a_display,
            sortable: true,
        },
        {
            name: 'Shift B',
            selector: rowData => rowData.team_b_display,
            sortable: true,
        },
        {
            name: 'Shift C',
            selector: rowData => rowData.team_c_display,
            sortable: true,
        },
        {
            name: 'Shift D',
            selector: rowData => rowData.team_d_display,
            sortable: true,
        },
    ])

    useEffect(() => {
        const getSingleSite = async () => {
            setIsLoading(true)
            const rows = []
            try {
                const res = await GetSingleSite(id)
                const data = res.data.data
                data?.departments?.forEach((item) => {
                    rows.push({
                        id: item?.id.toString() || "-",
                        department: item?.name || "-",

                        team_a_display: item?.team_a_display || "-",
                        team_a: item?.team_a || "-",

                        team_b_display: item?.team_b_display || "-",
                        team_b: item?.team_b || "-",

                        team_c_display: item?.team_c_display || "-",
                        team_c: item?.team_c || "-",

                        team_d_display: item?.team_d_display || "-",
                        team_d: item?.team_d || "-",
                    })
                })
                setSite(data)
                setRows(rows)
                setIsLoading(false)
            }
            catch (e) {
                setIsLoading(false)
                error(e?.response?.data?.message)
            }
        }

        getSingleSite()
    }, [])

    return (
        <div className='pages manage-sites-page site-detail-page'>
            <Container>
                <Row>
                    <Col xs={12} className="pt-2">
                        <h1>Department Details of Site: <b className='ms-1 text-grey'>{site?.location}</b></h1>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="mt-4">
                        <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={false} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SiteDepartmentsDetailPage