import React from 'react'
import { NavLink } from 'react-router-dom'
import PrivacyPolicy from '../dashboard/PrivacyPolicy'
import Assets from '../../constants/images'

const AuthPrivacyPolicyPage = () => {
    return (
        <div className='auth-card auth-privacy-policy-page'>
            <div className="logo-wrapper mb-30 d-block d-xl-none">
                <img src={Assets.Logo} alt="" />
            </div>

            <div className='header'>
                <NavLink className="text-grey d-block mb-2 w-400" to="/signin">Back</NavLink>
                <h1>Privacy Policy</h1>
            </div>

            <div className='mt-30'>
                <div className='wrapper'>
                    <PrivacyPolicy />
                </div>
            </div>
        </div>
    )
}

export default AuthPrivacyPolicyPage