import React, { useContext, useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import CustomInput from '../forms/CustomInput'
import { FIELD, KEYS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomRoleSelect from '../forms/CustomRoleSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import { CleanAndFormatData, HandleRegister, HandleShow } from '../../utils/helper'
import { roles } from '../../utils/constants'
import CustomUserSelect from '../forms/CustomUserSelect'
import ClearFormPopup from "../popup/ClearFormPopup"
import { ToastContainer } from 'react-toastify'
import { AddUsers, EditUser, GetSingleUser, GetUsers } from "../../services/users/users"
import { success, error } from '../../constants/msg'
import Loader from '../loader/Loader'
import { EditSite, GetSingleSite } from '../../services/sites/sites'
import CustomSiteAdminSelect from '../forms/CustomSiteAdminSelect'
import { TriggerAllSites } from '../../App'

const EditSitePopup = ({ editSitePopup, setEditSitePopup, selectedRow, getSites }) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [triggerAllSites, setTriggerAllSites] = useContext(TriggerAllSites)
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        if (formData?.site_admin) {
            formData.site_admin_id = formData?.site_admin?.value
        }
        try {
            const res = await EditSite(selectedRow.id, formData)
            getSites()
            setTriggerAllSites(!triggerAllSites)
            success(res.data.message)
            setEditSitePopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    const clearForm = () => {
        reset({
            [FIELD.location]: "",
            [FIELD.site_admin]: null
        })
        setClearFormPopup(false)
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [editSitePopup])

    useEffect(() => {
        const getSingleSite = async () => {
            try {
                const res = await GetSingleSite(selectedRow.id)
                const data = res.data.data
                console.log(data, "Data")
                reset({
                    [FIELD.location]: data?.location,
                    [FIELD.site_admin]: data?.site_admin ? { label: data?.site_admin?.full_name, value: data?.site_admin?.id } : null
                })
            }
            catch (e) {
                error(e?.response?.data?.message)
            }
        }

        if (selectedRow) {
            getSingleSite()
        }
    }, [selectedRow])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />


            <Modal
                className='theme-popup edit-site-popup'
                show={editSitePopup}
                onHide={() => setEditSitePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Edit Site</h1>
                                        <span className='times-icon' onClick={() => setEditSitePopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE LOCATION INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.location}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_LOCATION_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.NAME,
                                                    message: VALIDATIONS_TEXT.SITE_LOCATION_MAX
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.location}
                                                    name={FIELD.location}
                                                    placeholder="Site Location *"
                                                    type="text"
                                                    label="Site Location *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.location] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.location].message}</small>}

                                    </Col>

                                    {/* SITE ADMIN SELECT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.site_admin}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.SITE_ADMIN_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteAdminSelect
                                                    {...field}
                                                    name={FIELD.site_admin}
                                                    placeholder={"Select Site Admin"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site_admin] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site_admin].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Update"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditSitePopup
