import React, { useEffect, useState, createContext } from 'react'
import { BrowserRouter } from "react-router-dom";
import { error } from './constants/msg';
import Navigation from './navigation/Navigation';
import { GetUserRoleLocalStorage, GetAuthUserLocalStorage } from './services/localStorage/localStorage';
import { GetSitesV2 } from './services/sites/sites';
import { ToastContainer } from 'react-toastify';
const UserData = createContext()
const UserRole = createContext()
const AllSites = createContext()
const TriggerAllSites = createContext()

const App = () => {
    const [userData, setUserData] = useState(GetAuthUserLocalStorage())
    const [userRole, setUserRole] = useState(GetUserRoleLocalStorage())
    const [allSites, setAllSites] = useState([])
    const [triggerAllSites, setTriggerAllSites] = useState(false)

    useEffect(() => {
        // prevenet text selection on double click 
        document.body.addEventListener('mousedown', function (event) {
            if (event?.detail > 1) {
                event?.preventDefault();
            }
        }, false);


        const getAllSite = async () => {
            try {
                const params = { is_paginated: 0 }
                const res = await GetSitesV2(params)
                setAllSites(res?.data?.data)
            }
            catch (e) {
                error(e?.response?.data?.message)
            }
        }

        if (userData) {
            getAllSite()
        }
    }, [triggerAllSites])

    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <UserData.Provider value={[userData, setUserData]}>
                    <UserRole.Provider value={[userRole, setUserRole]}>
                        <AllSites.Provider value={[allSites, setAllSites]}>
                            <TriggerAllSites.Provider value={[triggerAllSites, setTriggerAllSites]}>
                                <Navigation />
                            </TriggerAllSites.Provider>
                        </AllSites.Provider>
                    </UserRole.Provider>
                </UserData.Provider>
            </BrowserRouter>
        </>
    )
}

export default App

export { UserData, UserRole, TriggerAllSites, AllSites }