import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { FIELD, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomDatePicker from '../forms/CustomDatePicker'
import CustomStatusSelect from '../forms/CustomStatusSelect'
import CustomSiteSelect from '../forms/CustomSiteSelect'
import CustomDepartmentSelect from '../forms/CustomDepartmentSelect'
import CustomTeamSelect from '../forms/CustomTeamSelect'
import CustomUserSelect from '../forms/CustomUserSelect'
import { ToastContainer } from 'react-toastify'
import ClearFormPopup from "../popup/ClearFormPopup"
import { AddCalendarEvent } from '../../services/calendar-events/calendar-events'
import { CleanAndFormatData } from '../../utils/helper'
import { error, success } from '../../constants/msg'
import Loader from '../loader/Loader'

const AddCalendarEventPopup = ({ addCalendarEventPopup, setAddCalendarEventPopup, rows, setRows }) => {
    const { register, handleSubmit, watch, reset, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [isDisabled, setIsDisabled] = useState(false)
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null, date: null
    })

    const clearForm = () => {
        reset({
            [FIELD.site]: null,
            [FIELD.department]: null,
            [FIELD.team]: null,
            [FIELD.user]: null,
            [FIELD.date]: null,
            [FIELD.event]: null,
        })
        setSelectedData({ role: null, site: null, department: null, team: null, user: null, date: null })
        setClearFormPopup(false)
    }

    useEffect(() => {
        // clear form when popup open or close
        clearForm()
    }, [addCalendarEventPopup])


    const onSubmit = async (data) => {
        setIsDisabled(true)
        const formData = CleanAndFormatData(data)
        formData[FIELD.event_status] = "0"
        try {
            const res = await AddCalendarEvent(formData)
            const obj = {
                id: res?.data?.data?.id || "-",
                date: res?.data?.data?.date || "-",
                event_name: res?.data?.data?.event_display || "-",
                user_name: res?.data?.data?.full_name || "-",
                status: res?.data?.data?.event_status || "-"
            }
            setRows([obj, ...rows])
            success(res.data.message)
            setAddCalendarEventPopup(false)
            setIsDisabled(false)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />


            <Modal
                className='theme-popup add-calendar-event-popup'
                show={addCalendarEventPopup}
                onHide={() => setAddCalendarEventPopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add Calendar Event</h1>
                                        <span className='times-icon' onClick={() => setAddCalendarEventPopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.site}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteSelect
                                                    {...field}
                                                    name={FIELD.site}
                                                    placeholder={"Select Site *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site].message}</small>}
                                    </Col>

                                    {/* DEPARTMENT SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.department}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DEPARTMENT_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDepartmentSelect
                                                    {...field}
                                                    name={FIELD.department}
                                                    placeholder={"Select Department *"}
                                                    styles=""
                                                    isClearable={true}
                                                    isPreFilled={false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.department] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.department].message}</small>}
                                    </Col>

                                    {/* TEAM SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.team}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.TEAM_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomTeamSelect
                                                    {...field}
                                                    name={FIELD.team}
                                                    placeholder={"Select Team *"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.team] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.team].message}</small>}
                                    </Col>

                                    {/* USER SELECT */}
                                    <Col xs={12} className={`mb-4`}>
                                        <Controller
                                            control={control}
                                            name={FIELD.user}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.USER_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomUserSelect
                                                    {...field}
                                                    name={FIELD.user}
                                                    placeholder={"Select User *"}
                                                    styles=""
                                                    isDependent={true}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.user] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.user].message}</small>}
                                    </Col>

                                    {/* DATE PICKER */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.date}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.DATE_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    {...field}
                                                    name={FIELD.date}
                                                    placeholder={"Select Date *"}
                                                    isClearable={true}
                                                    isDependent={true}
                                                    isDisabled={!getValues(`${[FIELD.user]}`) ? true : false}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.date] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.date].message}</small>}
                                    </Col>

                                    {/* STATUS SELECT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.event}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.EVENT_STATUS_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomStatusSelect
                                                    {...field}
                                                    name={FIELD.event}
                                                    placeholder={"Select Status *"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    state={selectedData}
                                                    setState={setSelectedData}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.event] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.event].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type='submit' className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddCalendarEventPopup 