import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const success = (msg) => toast.success(msg, {
    hideProgressBar: true,
    autoClose: 1500,
    position: "bottom-right"
});

export const error = (msg) => toast.error(msg, {
    hideProgressBar: true,
    autoClose: 1500,
    position: "bottom-right"
});