import React, { useEffect, useState } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import { BsCloudArrowDownFill, BsCloudArrowUpFill } from 'react-icons/bs'
import AddUserPopup from '../components/popup/AddUserPopup'
import CustomSearchInput from '../components/forms/CustomSearchInput'
import { Controller, useForm } from 'react-hook-form'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import { GetTeams } from '../services/teams/teams'
import { success, error } from '../constants/msg'
import CustomUserSelect from '../components/forms/CustomUserSelect'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import { HandleRegister } from '../utils/helper'
import { roles } from '../utils/constants'
import EditTeamPopup from '../components/popup/EditTeamPopup'

const ManageTeamsPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [search, setSearch] = useState("")
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [rows, setRows] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [editTeamPopup, setEditTeamPopup] = useState(false)
    const [columns, setColumns] = useState([
        {
            name: 'Team ID',
            selector: rowData => rowData.id,
            sortable: true,
        },
        {
            name: 'Site',
            selector: rowData => rowData.site,
            sortable: true,
        },
        {
            name: 'Department',
            selector: rowData => rowData.department,
            sortable: true,
        },
        {
            name: 'Team',
            selector: rowData => rowData.team,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="/manage-teams/team" row={row} rowData={rowData} setEditPopup={setEditTeamPopup} setSelectedRow={setSelectedRow} viewAction={true} />
        },
    ])
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const getTeams = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(search && { search }),
            }
            const res = await GetTeams(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id.toString() || "-",
                    site: item?.site_name || "-",
                    department: item?.department_name || "-",
                    team: item?.name || "-",
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        getTeams()
    }, [page.currentPage, search])

    useEffect(() => {
        if (selectedRow) {
            setSelectedData({
                ...selectedData,
                team: { label: selectedRow?.team, value: selectedRow?.id }
            })
        }
    }, [selectedRow])

    return (
        <>
            <EditTeamPopup editTeamPopup={editTeamPopup} setEditTeamPopup={setEditTeamPopup} selectedRow={selectedRow} selectedData={selectedData} setSelectedData={setSelectedData} getTeams={getTeams} />


            <div className='pages manage-teams-page'>
                <Container>
                    <Row>
                        <Col xs={12} className="pt-2">
                            <h1>Team Management</h1>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} lg={4} className="mb-4">
                            <CustomSearchInput
                                search={search}
                                onChange={(e) => setSearch(e.target.value)}
                                label="Search"
                                placeholder="Search"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className="mt-4">
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={false} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ManageTeamsPage