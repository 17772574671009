import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap'
import ThemeDataTable from '../components/dashboard/ThemeDataTable'
import StatsCard from '../components/dashboard/StatsCard'
import ThemesDataTable from '../components/dashboard/ThemeDataTable'
import Select from 'react-select';
import AddLongTermEventPopup from '../components/popup/AddLongTermEventPopup'
import { ToastContainer } from 'react-toastify'
import AddCalendarEventPopup from '../components/popup/AddCalendarEventPopup'
import AddSetupPostInformationPopup from '../components/popup/AddSetupPostInformationPopup'
import DeleteConfirmPopup from '../components/popup/DeleteConfirmPopup'
import { BulkDeletePost, DeletePost, GetPosts } from "../services/posts/posts"
import { BulkDeleteSchedule } from '../services/schedules/schedules'
import { success, error } from '../constants/msg'
import ThemeDataTableAction from '../components/dashboard/ThemeDataTableAction'
import EditSetupPostInformationPopup from '../components/popup/EditSetupPostInformationPopup'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import { Controller, useForm } from 'react-hook-form'
import CustomSiteSelect from '../components/forms/CustomSiteSelect'
import ClearFormPopup from '../components/popup/ClearFormPopup'

const SetupPostInformationPage = () => {
    const { register, handleSubmit, reset, watch, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [addSetupPostInformationPopup, setAddSetupPostInformationPopup] = useState(false)
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(false)
    const [page, setPage] = useState({ currentPage: 1, totalPage: 1, perPage: 10, totalRows: 0 })
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false)
    const [editSetupPostInformationPopup, setEditSetupPostInformationPopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowsForDelete, setSelectedRowsForDelete] = useState([])
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([
        {
            name: 'Post Name',
            selector: rowData => rowData.post_name,
            sortable: true,
        },
        {
            name: 'Post Type ',
            selector: rowData => rowData.post_type,
            sortable: true,
        },
        {
            name: 'Site Name',
            selector: rowData => rowData.site_name,
            sortable: true,
        },
        {
            name: 'Department Name',
            selector: rowData => rowData.department_name,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (rowData, row) => <ThemeDataTableAction link="" row={row} rowData={rowData} setEditPopup={setEditSetupPostInformationPopup} setSelectedRow={setSelectedRow} viewAction={false} />
        },
    ])
    const [selectedData, setSelectedData] = useState({
        role: null, site: null, department: null, team: null, user: null
    })

    const getPosts = async () => {
        const rows = []
        setIsLoading(true)
        try {
            const params = {
                page: page.currentPage,
                per_page: page.perPage,
                ...(selectedData.site && { site_id: selectedData.site.value })
            }
            const res = await GetPosts(params)
            const data = res.data
            data?.data?.forEach((item) => {
                rows.push({
                    id: item?.id || "-",
                    post_name: item?.title || "-",
                    post_type: item?.type_display || "-",
                    site_name: item.site_name || "-",
                    department_name: item.department_name || "-",
                })
            })
            setRows(rows)
            setPage({ ...page, totalPage: data.meta.last_page, totalRows: data.meta.total })
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            error(e?.response?.data?.message)
        }
    }

    const deletePost = async () => {
        setIsDeleteDisabled(true)
        try {
            if (selectedRowsForDelete.length === 1) {
                const res = await DeletePost(selectedRowsForDelete[0]?.id)
                success(res.data.message)
            }
            else {
                const idsArray = []
                selectedRowsForDelete.forEach((item) => {
                    idsArray.push(item.id)
                })
                const res = await BulkDeletePost(idsArray)
                success(res.data.message)
            }
            setIsDeleteDisabled(false)
            setDeleteConfirmPopup(false)
            setSelectedRowsForDelete([])
            setToggleClearRows(!toggledClearRows)
            getPosts()
        }
        catch (e) {
            setIsDeleteDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        getPosts()
    }, [page.currentPage, selectedData])

    return (
        <>
            <AddSetupPostInformationPopup addSetupPostInformationPopup={addSetupPostInformationPopup} setAddSetupPostInformationPopup={setAddSetupPostInformationPopup} rows={rows} setRows={setRows} />
            <EditSetupPostInformationPopup editSetupPostInformationPopup={editSetupPostInformationPopup} setEditSetupPostInformationPopup={setEditSetupPostInformationPopup} getPosts={getPosts} selectedRow={selectedRow} />
            <DeleteConfirmPopup deleteConfirmPopup={deleteConfirmPopup} setDeleteConfirmPopup={setDeleteConfirmPopup} isDeleteDisabled={isDeleteDisabled} deleteMethod={deletePost} />


            <div className='setup-post-information-page'>
                <Container className='p-0'>
                    <Row>
                        <Col xs={12} md={5} lg={4} className="pt-2">
                            <h1>Setup Post Information</h1>
                        </Col>

                        <Col xs={12} md={7} lg={8} className="mt-4 mt-md-0 mb-4">
                            <Row className="justify-content-end">
                                {/* SITE FILTER */}
                                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <Controller
                                        control={control}
                                        name={FIELD.site}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: VALIDATIONS_TEXT.SITE_REQUIRED,
                                            }
                                        }}
                                        render={({ field }) => (
                                            <CustomSiteSelect
                                                {...field}
                                                name={FIELD.site}
                                                placeholder={"Select Site"}
                                                styles="small-select"
                                                isClearable={true}
                                                isPreFilled={false}
                                                state={selectedData}
                                                setState={setSelectedData}
                                                setValue={setValue}
                                                getValues={getValues}
                                            />
                                        )}
                                    />
                                </Col>

                                <Col xs={12} sm={6} md={6} lg={4} xl={4}>
                                    <Button onClick={() => setAddSetupPostInformationPopup(true)} className='mt-4 mt-sm-0 w-100 btn-solid'>+ Add New</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col xs={12}>
                            <ThemeDataTable columns={columns} rows={rows} page={page} setPage={setPage} isLoading={isLoading} setDeleteConfirmPopup={setDeleteConfirmPopup} selectedRowsForDelete={selectedRowsForDelete} setSelectedRowsForDelete={setSelectedRowsForDelete} selectableRows={true} toggledClearRows={toggledClearRows} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SetupPostInformationPage