import React, { useState, useEffect, useRef } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { Bar, Chart, Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const ThemeChart = (props) => {
    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
            },
            y: {
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 1
                }
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
            },
        },
    };
    const [data, setData] = useState({
        labels: [],
        datasets: [],
        backgroundColor: ""
    })

    useEffect(() => {
        if (props) {
            setData({
                labels: props?.labels,
                datasets: props?.datasets,
            })
        }
    }, [props])

    return (
        <div className='theme-chart'>
            <Bar options={options} data={data} />
        </div>
    )
}

export default ThemeChart