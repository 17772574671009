import api from "../index";
import { apiUrl, constant } from "../../utils/constants";
import axios from "axios";
import { GetTokenLocalStorage } from "../localStorage/localStorage"

export const GetProfile = () => api.get(`${constant.V1}${apiUrl.users}${apiUrl.me}/`)
export const EditProfile = (data) => axios.put(`${constant.V1}${apiUrl.users}${apiUrl.me}/`, data, {
    headers: {
        Authorization: 'token ' + GetTokenLocalStorage()
    }
})
