import React, { useContext, useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { ToastContainer } from 'react-toastify'
import { UserData } from '../App'
import CustomInput from '../components/forms/CustomInput'
import EditProfilePopup from '../components/popup/EditProfilePopup'
import { FIELD, VALIDATIONS_TEXT } from '../constants/app-constants'
import Assets from '../constants/images'

const MyProfilePage = ({ children }) => {
    const [editProfilePopup, setEditProfilePopup] = useState(false)
    const [userData, setUserData] = useContext(UserData)

    return (
        <>
            <EditProfilePopup editProfilePopup={editProfilePopup} setEditProfilePopup={setEditProfilePopup} />


            <div className='pages my-profile-page'>
                <Container>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <h1>Settings</h1>
                        </Col>

                        <Col md={5} lg={4} xl={3} className="d-none d-md-block">
                            {children}
                        </Col>

                        <Col md={7} lg={8} xl={6}>
                            <div className="content-wrapper">
                                <div className="mb-4">
                                    <Row>
                                        <Col xs={12}>
                                            <h1>My Profile</h1>
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col xs={12}>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='user-profile-wrapper'>
                                                        <img src={userData?.image ? userData.image : Assets.ProfilePlaceholderImage} onError={(e) => e.target.src = Assets.ProfilePlaceholderImage} alt="" />
                                                    </div>

                                                    <div className='mx-3'>
                                                        <h2>{userData?.full_name || "-"}</h2>
                                                        <h6 className='mt-1'>{userData?.role_name || "-"}</h6>
                                                        <h6 className='mt-1'>{userData?.email || "-"}</h6>
                                                    </div>
                                                </div>

                                                <div>
                                                    <Button className="mt-2 btn-solid btn-edit" onClick={() => setEditProfilePopup(true)}>Edit</Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default MyProfilePage