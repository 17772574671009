import React, { useContext, useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import ChangePasswordForm from '../components/authentication/ChangePasswordForm'

const ChangePasswordPage = ({ children }) => {
    return (
        <div className='pages change-password-page'>
            <Container>
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1>Settings</h1>
                    </Col>

                    <Col md={5} lg={4} xl={3} className="d-none d-md-block">
                        {children}
                    </Col>

                    <Col md={7} lg={8} xl={6}>
                        <div className="content-wrapper">
                            <div className="mb-4">
                                <Row>
                                    <Col xs={12} sm={8} md={10} lg={7}>
                                        <h1 className="mb-2">Change Password</h1>
                                        <p>Your new password must be different from your old password.</p>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <ChangePasswordForm />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ChangePasswordPage