import React from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'

const CustomSearchInput = (props) => {
    return (
        <div className='custom-search-input'>
            <DebounceInput
                className="form-control debounce-search-input"
                placeholder={props.placeholder}
                value={props.search}
                onChange={props.onChange}
                debounceTimeout={700}
                minLength={1}
                maxLength={32}
            />
        </div>
    )
}

export default CustomSearchInput