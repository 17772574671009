import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

const CustomInput = (props) => {
    const handleChange = (event) => {
        const value = event.target.value;

        // Log the value to the console
        console.log('Input value:', value);

        // Call the onChange prop with the value
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <FloatingLabel label={props.label}>
            <Form.Control
                {...props}
                disabled={props.isDisabled}
                type={props.type}
                placeholder={props.placeholder}
                onChange={handleChange}
            />
        </FloatingLabel>
    );
};

export default CustomInput;
