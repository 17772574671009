import React, {useRef} from 'react'
import { Container } from 'react-bootstrap'
import { Row, Col } from "react-bootstrap"
import Assets from '../../constants/images'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination,Autoplay } from "swiper";


const AuthLayout = ({ children }) => {
    return (
        <section className='auth-layout'>
            <div className='d-flex h-100'>
                <div className='left-wrapper'>
                    <div className='bg-wrapper d-none d-md-block'></div>
                    <div className="wrapper-container">
                        {children}
                    </div>
                </div>

                <div className='right-wrapper'>
                    <div className='w-100 h-100 d-flex align-items-center'>
                        <div className='wrap-content'>
                            <div className='logo-wrapper'>
                                <img src={Assets.Logo} alt="" />
                            </div>

                            <div className='content-wrapper mt-4'>
                                <h1>Welcome to WTT</h1>
                                <Swiper
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Pagination,Autoplay]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <ul className='mt-20'>
                                        <li>
                                            <span className="heading">Our Vision: </span>
                                            Provide our game-changing work scheduling software to help companies optimize costs and keep employees happy
                                        </li>
                                        <li>
                                            <span className="heading">Our Mission: </span>
                                            Deliver digital automated solutions in a fit-for-purpose, easy to use customizable format
                                        </li>
                                        <li>
                                            <span className="heading"> Our Guarantee: </span>
                                            Premier product designed from industry experience and expertise
                                        </li>
                                    </ul></SwiperSlide>
                                    <SwiperSlide>

                                        <div className="heading mt-20">Our Product</div>
                                        <ul className='dot-ul '>
                                        <li>On-line access ready to use with minimal user training required and data privacy assurance</li>
                                        <li>Simplified, streamlined user experience utilizing built-in scheduling optimization algorithm</li>
                                        <li>Capable of optimizing atypical staffing scenarios allowing for increased scheduling flexibility</li>
                                        <li>Allows supervisors to create long term optimized schedules in minutes</li>
                                        <li>Delivers business advantage for companies in multiple dimensions</li>
                                        <li>Fatigue Management (API-755) – compliance with API guidance and employee safety and health</li>
                                    </ul></SwiperSlide>
                                    <SwiperSlide>
                                        <div className="heading mt-20">Functionality
                                        </div>
                                        <ul className='dot-ul '>
                                        <li>Built-in 5 most prominent industry 12hr 24x7 work schedules – DuPont, EOWEO, 4 on/4 off, 4/5, and 7 on/7 off; ability to program additional schedules</li>
                                        <li>Slider and Vacation relief optimization – ability to controllably schedule out for a longer duration drives the value</li>
                                        <li>Swaps – sharing vacation reliefs across shift teams to allow employee flexibility within work rules and site practices</li>
                                        <li>Fatigue Management – proactively anticipates and avoids unfavorable situations that would generate conflicts with API-755 guidelines</li>
                                        <li>Shift Competency – Enables Front Line Supervisors to ensure collective shift team competency while adjusting for non-routine shift activities</li>
                                        <li>Emergency Response – Line of sight to ensure critical requirements are met with trained ERT (fire brigade) and EMT (Medical responders) for every shift</li>
                                        <li>Customized Employee and Labor Relations Flexibility – flexibility built-in to leverage site work practices and comply with work applicable work agreements</li> </ul></SwiperSlide>
                                    <SwiperSlide>  <div className="heading  mt-20">Contact Us</div>
                                        <ul className='dot-ul'>
                                        <li>409-679-3404
                                        </li></ul>
                                          </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuthLayout