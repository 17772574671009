export const constant = {
    DEVICE_TYPE: "web",
    DEVICE_TOKEN: "",
    LOCAL_STORAGE_TOKEN: "WTT_AUTH_ACCESS_TOKEN",
    LOCAL_STORAGE_USER: "WTT_USER_DATA",
    LOCAL_STORAGE_ROLE: "WTT_USER_ROLE",
    BASE_URL: "https://api.wtttechnology.com/api/",
    // BASE_URL: "http://18.190.109.153:8001/api/",
    IMAGE_DIR_NAME: "images",
    VIDEO_DIR_NAME: "videos",
    V1: "v1",
    V2: "v2",
}

export const roles = {
  super_user: 20,
  site_admin: 30,
  supervisor: 40,
  employee: 50,
};

export const roles_options = [
  {
    label: "Company Admin",
    value: 20,
  },
  {
    label: "Site Admin",
    value: 30,
  },
  {
    label: "Supervisor",
    value: 40,
  },
  {
    label: "Employee",
    value: 50,
  },
];

export const teams_options = [
  {
    label: "Team A",
    value: 1,
  },
  {
    label: "Team B",
    value: 2,
  },
  {
    label: "Team C",
    value: 3,
  },
  {
    label: "Team D",
    value: 4,
  },
];

export const pay_type_options = [
  {
    label: "Salary",
    value: 1,
  },
  {
    label: "Wage",
    value: 2,
  },
];

export const STATUS = {
  pending: 0,
  approved: 1,
  rejected: 2,
};

export const recent_events_options = [
  {
    label: "Display Recent Event",
    name: "recent_events",
    value: 1,
  },
  {
    label: "12 Months",
    name: "month",
    value: 12,
  },
  {
    label: "Pending",
    name: "event_status",
    value: 0,
  },
  {
    label: "Approved",
    name: "event_status",
    value: 1,
  },
  {
    label: "Rejected",
    name: "event_status",
    value: 2,
  },
];

export const event_type_options = [
  {
    label: "Off",
    value: 1,
  },
  {
    label: "Train/Assign",
    value: 2,
  },
];

export const schedule_options = [
  {
    label: "4 Days Mon-Thu",
    value: 1,
  },
  {
    label: "5 Days Mon-Fri",
    value: 2,
  },
  {
    label: "7 Days Mon-Sun",
    value: 3,
  },
];

export const post_type_options = [
  {
    label: "Half Post",
    value: 1,
  },
  {
    label: "Full Post",
    value: 2,
  },
];

export const competency_select_options = [
  {
    label: "OT & Work Post Data",
    value: 1,
  },
  {
    label: "Competency & ER Data",
    value: 2,
  },
];

export const shift_type_options = [
  {
    label: "Day",
    value: 1,
  },
  {
    label: "Night",
    value: 2,
  },
];

export const shift_type = {
  day: 1,
  night: 2,
};

export const ot_criteria_options = [
  {
    label: "OT Hours",
    value: 1,
  },
  {
    label: "Seniority",
    value: 2,
  },
];

export const double_time_day_options = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
];

export const slider_tolerance_options = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
];

export const s3Credential = {
  bucketName: "",
  region: "",
  accessKeyId: "",
  secretAccessKey: "",
  s3EndPoint: "",
  fileSize: "",
  dirName: "",
};

export const errorMessages = {
  fileSize: "The file size is too large",
  fileSuccess: "file uploaded successfully",
  fileError: "Error in uploading file",
};

export const days_array = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const tabs_array = [
  "DuPont Schedule",
  "4on/4off Schedule",
  "EOWEO Schedule",
  "4/5 Schedule",
  "7on/7off Schedule",
];

export const apiUrl = {
  auth: "/auth",
  login: "/login",
  signup: "/signup",
  forget_password: "/forget-password",
  verify_otp: "/otp/verify",
  reset_password: "/reset-password",
  change_password: "/change-password",
  logout: "/logout",
  notifications: "/notifications",
  users: "/users",
  sites: "/sites",
  departments: "/departments",
  teams: "/teams",
  members: "/members",
  teams_members: "/teams/members",
  bulk_delete: "/bulk_delete",
  bulk_create: "/bulk_create",
  csv_sample: "/csv_sample",
  available_site_admins: "/available_site_admins",
  calendar_event: "/calendarevent",
  long_term_event: "/longtermevent",
  is_employee_working: "/is_employee_working",
  schedule: "/schedule",
  rerun: "/rerun",
  posts: "/posts",
  off_time: "/offtime",
  vacation: "/vacation",
  schedule_detail: "/scheduledetail",
  me: "/me",
  pages: "/pages",
  terms_and_conditions: "/terms_and_conditions",
  privacy_policy: "/privacy_policy",
  faqs: "/faqs",
  avg_overtime: "/avg_overtime",
  shift_efficiency: "/shift_efficiency",
  competency: "/competency",
  competency_er_data: "/competencyerdata",
  upcoming_events: "/upcoming_events",
  avg_post_competency: "/avg_post_competency",
  work_schedule: "/workschedule",
  dupont: "/dupont",
  four_on_four_off: "/four_on_four_off",
  eoweo: "/eoweo",
  four_five: "/fourfive",
  seven_on_seven_off: "/seven_on_seven_off",
  read_all: "/read_all",
  read: "/read",
  last_schedule: "/last_schedule",
  approve: "/approve",
  reject: "/reject",
  schedule_upload: "/schedule/upload",
};
