import React, { useContext, useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Button, FloatingLabel } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { TimesIcon } from '../../constants/svgs'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import CustomSiteAdminSelect from '../forms/CustomSiteAdminSelect'
import { FIELD, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants'
import CustomInput from '../forms/CustomInput'
import Loader from '../loader/Loader'
import { CleanAndFormatData } from '../../utils/helper'
import { error, success } from '../../constants/msg'
import { AddSite } from '../../services/sites/sites'
import ClearFormPopup from './ClearFormPopup'
import { TriggerAllSites } from '../../App'

const AddSitePopup = ({ addSitePopup, setAddSitePopup, rows, setRows, getSites }) => {
    const [clearFormPopup, setClearFormPopup] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [triggerAllSites, setTriggerAllSites] = useContext(TriggerAllSites)


    const { register, handleSubmit, watch, reset, setValue, getValues, control, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const clearForm = () => {
        reset({
            [FIELD.location]: "",
            [FIELD.site_admin]: null
        })
        setClearFormPopup(false)
    }

    const onSubmit = async (data) => {
        setIsDisabled(true)
        try {
            if (data?.site_admin) {
                data.site_admin_id = data?.site_admin?.value
            }

            const formData = CleanAndFormatData(data)
            const res = await AddSite(formData)
            getSites()
            setIsDisabled(false)
            setAddSitePopup(false)
            setTriggerAllSites(!triggerAllSites)
            success(res.data.message)
        }
        catch (e) {
            setIsDisabled(false)
            error(e?.response?.data?.message)
        }
    }

    useEffect(() => {
        clearForm()
    }, [addSitePopup])

    return (
        <>
            <ClearFormPopup clearFormPopup={clearFormPopup} setClearFormPopup={setClearFormPopup} clearForm={clearForm} />

            <Modal
                className='theme-popup add-site-popup'
                show={addSitePopup}
                onHide={() => setAddSitePopup(false)}
                size="md"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="wrapper p-3">
                        <div className='header'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h1>Add Site</h1>
                                        <span className='times-icon' onClick={() => setAddSitePopup(false)}><TimesIcon /></span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='form-wrapper mt-5'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    {/* SITE LOCATION INPUT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.location}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.SITE_LOCATION_REQUIRED,
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.NAME,
                                                    message: VALIDATIONS_TEXT.SITE_LOCATION_MAX
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomInput
                                                    {...field}
                                                    isDisabled={false}
                                                    controlId={FIELD.location}
                                                    name={FIELD.location}
                                                    placeholder="Site Location *"
                                                    type="text"
                                                    label="Site Location *"
                                                    styles=""
                                                />
                                            )}
                                        />
                                        {errors[FIELD.location] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.location].message}</small>}

                                    </Col>

                                    {/* SITE ADMIN SELECT */}
                                    <Col xs={12} className="mb-4">
                                        <Controller
                                            control={control}
                                            name={FIELD.site_admin}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: VALIDATIONS_TEXT.SITE_ADMIN_REQUIRED,
                                                }
                                            }}
                                            render={({ field }) => (
                                                <CustomSiteAdminSelect
                                                    {...field}
                                                    name={FIELD.site_admin}
                                                    placeholder={"Select Site Admin"}
                                                    styles=""
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                />
                                            )}
                                        />
                                        {errors[FIELD.site_admin] && <small className='text-start d-block text-danger mt-1'>{errors[FIELD.site_admin].message}</small>}
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3">
                                    <Col xs={6} sm={4}>
                                        <Button className='btn-outline w-100' onClick={() => setClearFormPopup(true)}>Clear</Button>
                                    </Col>

                                    <Col xs={6} sm={4}>
                                        <Button disabled={isDisabled} type="submit" className='btn-solid w-100'>
                                            {isDisabled ? <Loader /> : "Add"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddSitePopup