import React from 'react'
import { ClockIconBg } from '../../constants/svgs'

const StatsCard = (props) => {
    const handleScheduleTypeDisplay = (value) => {
        if(value === "Four On Four Off Schedule"){
            return "4on/4off Schedule" 
        }
        else if (value === "Seven On Seven Off Schedule"){
            return "7on/7off Schedule" 
        }
        else if (value === "Four Five Schedule"){
            return "4/5 Schedule" 
        }
        else{
            return value 
        }
    }

    return (
        <div className='stats-card'>
            <div className='wrapper d-flex  flex-column-reverse flex-sm-row justify-content-between'>
                <div className='pe-2'>
                    <h6>{props?.heading}</h6>
                    <h2>{handleScheduleTypeDisplay(props.value)}</h2>
                </div>

                <div className='mb-3 mb-sm-0'>
                    <ClockIconBg />
                </div>
            </div>
        </div>
    )
}

export default StatsCard