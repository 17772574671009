const Assets = {
    Logo: require("../assets/img/logo.png"),
    DashboardLogo: require("../assets/img/dashboard-logo.png"),
    UserImage: require("../assets/img/user-image.png"),
    CsvImage: require("../assets/img/csv-image.png"),
    PdfImage: require("../assets/img/Pdflogo.png"),
    ProfilePlaceholderImage: require("../assets/img/profile-placeholder.png"),

    DupontImage: require("../assets/img/dupont-img.png"),
    FourOnFourImage: require("../assets/img/four-on-four-img.png"),
    EoweoImage: require("../assets/img/eoweo-img.png"),
    FourFiveImage: require("../assets/img/four-five-img.png"),
    SevenOnSevenImage: require("../assets/img/seven-on-seven-img.png"),
}

export default Assets 